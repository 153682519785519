import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Index.vue";
import store from "@/store";
import i18n from "@/locale/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: i18n.t("menu.home"),
    },
  },
  {
    path: "/ucenter",
    name: "ucenter",
    meta: {
      title: i18n.t("menu.ucenter"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Index.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ucenter/assets/PledgedAssets.vue"
          ),
      },
      {
        path: "chain",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ucenter/assets/ChainAssets.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: i18n.t("menu.login"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/Index.vue"),
  },
  {
    path: "/risk",
    name: "risk",
    meta: {
      title: i18n.t("menu.risk"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Risk.vue"),
  },
  {
    path: "/customer",
    name: "customer",
    meta: {
      title: i18n.t("menu.customer"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Customer.vue"),
  },
  {
    path: "/swap",
    name: "swap",
    meta: {
      title: i18n.t("menu.swap"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/swap/Index.vue"),
  },
  {
    path: "/select",
    name: "select",
    meta: {
      title: i18n.t("menu.select"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/common/SelectToken.vue"),
  },
  {
    path: "/borrow",
    name: "borrow",
    meta: {
      title: i18n.t("menu.borrow"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/borrow/Index.vue"),
  },
  {
    path: "/pool",
    name: "pool",
    meta: {
      title: i18n.t("menu.pool"),
    },
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pool/PoolProduct"),
      },
      {
        path: "records",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pool/PoolRecord"),
      },
      {
        path: "balanceLog",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/pool/PoolBalanceLog"
          ),
      },
      {
        path: "team",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pool/PoolTeam"),
      },
    ],
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pool/Index.vue"),
  },
  {
    path: "/level",
    name: "level",
    meta: {
      title: i18n.t("menu.level"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Level"),
  },
  // {
  // 	path: '/share',
  // 	name: 'share',
  // 	meta: {
  // 		title: '分享'
  // 	},
  // 	component: () => import( /* webpackChunkName: "about" */ '../views/ucenter/share.vue')
  // },
  {
    path: "/account",
    name: "account",
    meta: {
      title: i18n.t("menu.account"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Account.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    meta: {
      title: i18n.t("menu.withdraw"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/Withdraw.vue"),
  },
  {
    path: "/market-fund",
    name: "market_fund",
    meta: {
      title: i18n.t("menu.market_fund"),
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ucenter/MarketFund.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const whiteRoutes = ["/", "/customer", "/login"];

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  let token = store.state.globals.token;
  if (token != null && token != "") {
    // 用户已登录
    if (to.path === "/login") {
      // 如果目标路由是登录或注册页面，则重定向到根路径
      next("/");
    } else {
      // 允许用户继续访问目标路由
      next();
    }
  } else {
    // 用户未登录
    if (whiteRoutes.includes(to.path)) {
      // 目标路由在白名单中，允许用户访问
      next();
    } else {
      // 目标路由不在白名单中，重定向到登录页
      next("/login");
    }
  }
});

//解决vue-router重复点击报错问题（this.$router.push()）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
