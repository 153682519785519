export default {
  // 服务语言
  serve: {
    common: {
      verifyFailed: "검증 실패",
      login: "먼저 로그인하세요",
    },
    approval: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
      lockFailed: "스테이킹 실패",
      lockSuccess: "스테이킹 성공",
      notAddress: "시스템에 주소가 구성되어 있지 않습니다",
      assetsLimit: "귀하의 자산이 스테이킹 최소 한도에 도달하지 않았습니다",
    },
    approvalGain: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    auth: {
      querySuccess: "조회 성공",
      notSupportedChainType: "지원되지 않는 공중 체인 유형",
      alreadyRegistered: "이미 등록되었습니다",
      loginFailedUnsupportedType: "지원되지 않는 유형",
      accountNotFound: "계정이 존재하지 않습니다",
      incorrectPassword: "비밀번호 오류",
    },
    base: {
      notDomin: "구성되지 않은 도메인",
    },
    block: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    borrow: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    borrowOrder: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
      notSupportedBorrow: "현재 토큰은 대출을 지원하지 않습니다",
      notSupportedCrossChain: "교차 체인을 지원하지 않습니다",
      orderAmountTooSmall: "주문 금액이 너무 작습니다",
      orderAmountTooLarge: "주문 금액이 너무 큽니다",
      addSuccess: "추가 성공",
      addFailed: "추가 실패",
      orderNotFound: "주문이 존재하지 않습니다",
    },
    exchange: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    exchangeOrder: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
      notSupportedExchange: "현재 토큰은 교환을 지원하지 않습니다",
      exchangeAmountTooSmall: "교환 금액이 너무 작습니다",
      exchangeAmountTooLarge: "교환 금액이 너무 큽니다",
      addSuccess: "추가 성공",
      addFailed: "추가 실패",
    },
    lixibao: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
      userInfoNotFound: "사용자 정보를 찾을 수 없습니다",
      insufficientBalance: "잔액 부족",
      addSuccess: "추가 성공",
    },
    pool: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    poolGoods: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    poolOrder: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
      productNotFound: "현재 제품이 존재하지 않습니다",
      productOutOfStock: "현재 제품은 품절되었습니다",
      orderNotFound: "주문이 존재하지 않습니다",
      orderNotInProfit: "현재 주문은 수익 중이 아닙니다",
      userInfoNotFound: "사용자 정보를 찾을 수 없습니다",
      addSuccess: "추가 성공",
      addFailed: "추가 실패",
      applyWithdrawSuccess: "출금 신청 성공",
      applyWithdrawFailed: "출금 신청 실패",
    },
    token: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    tokenPrice: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    user: {
      accountNotFound: "계정이 존재하지 않습니다",
      passwordError: "비밀번호 오류",
      resetPwdSuccess: "비밀번호 재설정 성공",
      resetPwdFailed: "비밀번호 재설정 실패",
      setNotifySuccess: "설정 성공",
      setNotifyFailed: "설정 실패",
      loginSuccess: "로그인 성공",
      loginFailed: "로그인 실패",
      emptyPwd: "비밀번호 재설정하지 않음",
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    vip: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    withdraw: {
      querySuccess: "조회 성공",
      queryFailed: "조회 실패",
    },
    withdrawOrder: {
      querySuccess: "조회 성공",
      insufficientBalance: "잔액 부족",
      notSupportWithdrawal: "현재 토큰은 출금을 지원하지 않습니다",
      notSupportBlockchain: "다른 블록체인의 토큰은 출금을 지원하지 않습니다",
      notSupportBlockchainWithdrawal:
        "현재 블록체인은 출금을 지원하지 않습니다",
      exceedLimit: "출금 금액이 제한을 초과하거나 제한 이하입니다",
      submitSuccess: "출금 제출 성공",
      orderNotFound: "출금 주문이 존재하지 않습니다",
      withdrawCanceled: "출금이 취소되었습니다",
      userNotFound: "사용자가 존재하지 않습니다",
    },
  },
  // 前端语言

  menu: {
    home: "홈",
    login: "사용자 로그인",
    risk: "위험 정보",
    customer: "고객 센터",
    ucenter: "사용자 센터",
    swap: "스왑",
    select: "코인 선택",
    borrow: "대출",
    pool: "유동성 마이닝",
    level: "등급",
    account: "계정 상세정보",
    withdraw: "출금",
    market_fund: "마켓 펀드",
  },
  header: { subtitle: "유동성 풀" },
  common: {
    cancel: "취소",
    confirm: "확인",
    cancelPay: "사용자가 결제 취소",
    enterPassword: "비밀번호를 입력하세요",
    inactivated: "귀하의 계정이 활성화되지 않았습니다",
    trxInsufficient: "주문을 지불하기에 충분한 TRX가 부족합니다",
    copySuccess: "{msg} 복사 성공",
    copyFailed: "복사 실패",
  },
  app: {
    lineWallet: "지갑 연결 중...",
  },
  foot: {
    home: "홈",
    swap: "스왑",
    borrow: "대출",
    pool: "마이닝 풀",
    ucenter: "내 정보",
  },
  language: {
    title: "언어 선택",
  },
  extend: {
    snackbar: {
      close: "닫기",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "확신하는",
      success: "성공적인 거래",
      fail: "거래 실패",
    },
  },
  home: {
    typewriter1: "암호화폐의 혁명이",
    typewriter2: "조용히 일어나고 있습니다.",
    typewriter3: "그럼, 준비되셨나요? 그렇다면 지금 우리에게 합류하세요!",
    urlSubtitle: "우리는 무엇을 할 수 있을까요?",
    blockSubtitle: "지원되는 코인",
    swapTitle: "암호화폐 교환",
    borrowTitle: "담보 대출",
    poolTitle: "유동성 마이닝",
    swapDesc: "다중 체인 간 교환을 지원하여 다중 체인 간 상호 운용성 구현",
    borrowDesc: "담보 대출 비즈니스를 제공하여 블록체인 가치 속성 강화",
    poolDesc: "보유한 코인으로 수익 창출을 플랫폼에 참여",
    swapBtn: "교환",
    borrowBtn: "대출",
    poolBtn: "마이닝 풀",
  },

  swap: {
    title: "스왑 시장",
    subtitle:
      "다양한 코인을 자유롭게 교환하고, 플랫폼은 수수료를 적게 부과합니다.",
    placeholder: "수량을 입력하세요",
    toAddressPlaceholder: "{block_name} 주소를 입력하세요",
    toAddressLabel: "수령 주소",
    currentRate: "현재 환율",
    commission: "교환 수수료",
    btn: "교환",
    records: "교환 기록",
    goPay: "지불하러 가기",
    dialogTitle: "교환 알림",
    dialogMessage: "교환을 시작하시겠습니까?",
    emptyAddress: "수령 주소를 입력하세요",
    createOrderFail: "주문 생성에 실패했습니다",
    orderTitle: "스왑 주문",
    orderSubtitle: "스왑 주문 정보 확인",
    orderNumber: "주문 번호",
    status: {
      all: "전체",
      wait: "지불 대기",
      paySuccess: "지불 성공",
      success: "성공",
      fail: "실패",
    },
    tableHeaders: {
      swap_token: "교환 코인",
      from_num: "지불 수량",
      transfer_num: "수령 수량",
      transfer_commission: "거래 수수료",
      rate: "환율",
      create_time: "거래 시간",
      status: "상태",
    },
    orderItems: {
      swap_token: "교환 코인",
      transfer_num: "예상 수령",
      transfer_commission: "거래 수수료",
      to_address: "수령 주소",
      from_num: "결제 금액",
    },
  },

  borrow: {
    title: "담보 대출",
    subtitle:
      "코인을 담보로 다른 코인을 대출하고 가치를 창출합니다. 동일 체인 대출만 지원됩니다.",
    fromPlaceholder: "담보로 제공할 수량을 입력하세요",
    fromLabel: "담보 수량",
    toPlaceholder: "대출 가능 수량을 입력하세요",
    toLabel: "대출 가능",
    toAddressPlaceholder: "{block_name} 주소를 입력하세요",
    toAddressLabel: "수령 주소",
    currentRate: "현재 환율",
    pledge: "담보율",
    btn: "대출",
    records: "거래 기록",
    goPay: "지불하러 가기",
    addBorrow: "담보 추가",
    repayment: "상환",
    none: "없음",
    dialogTitle: "대출 알림",
    dialogSubtitle: "담보 대출을 시작하시겠습니까?",
    emptyAddress: "수령 주소를 입력하세요",
    notChain: "다른 체인 간 대출은 지원되지 않습니다.",
    createOrderFail: "주문 생성에 실패했습니다",
    orderTitle: "대출 정보",
    orderSubtitle: "대출 주문 정보 확인",
    repaymentTitle: "상환 주문",
    repaymentSubtitle: "대출 상환 주문 정보",
    subOrderDialogTitle: "담보 수량",
    subOrderDialogPlaceholder: "담보 수량을 입력하세요",
    subOrderTitle: "담보 추가 정보",
    subOrderSubtitle: "담보 추가 주문 정보",
    orderNumber: "주문 번호",
    status: {
      all: "전체",
      wait: "지불 대기",
      borrowing: "대출 중",
      over: "종료됨",
      disbursement: "대출 지급 중",
      repaymentSuccess: "상환 성공",
      liquidated: "청산됨",
    },
    tableHeaders: {
      borrow_token: "교환 코인",
      from_num: "담보 수량",
      transfer_num: "대출 수량",
      transfer_commission: "거래 수수료",
      pledge: "담보율",
      interest: "이자",
      create_time: "거래 시간",
      status: "상태",
      operate: "조작",
    },
    repaymenItems: {
      swap_token: "교환 코인",
      transfer_num: "대출 원금",
      interest: "이자",
      repayment: "상환 수량",
      from_num: "담보 반환",
    },
    subOrderItems: {
      from_num: "지불 금액",
      token: "담보 코인",
    },
    orderItems: {
      token_symbol: "교환 코인",
      transfer_num: "예상 수령",
      transfer_commission: "거래 수수료",
      pledge: "담보율",
      to_address: "수령 주소",
      from_num: "지불 금액",
    },
  },

  pool: {
    header: "유동성 마이닝",
    headerSubtitle:
      "새로운 스테이킹 마이닝에 참여하여 듀얼 통화 수익을 더하세요",
    totalEarnings: "총 수익",
    todayEarnings: "오늘의 수익",
    minePool: "마이닝 풀",
    mySupply: "내 수익",
    myTeam: "팀",
    poolTime: "블록 시간",
    product: {
      lockBtn: "스테이킹 혜택",
      myLockPools: "내 스테이킹",
      joined: "참여함",
      earned: "수익",
      profitTime: "수익 시간",
      currentChain: "현재 블록체인",
      otherChains: "다른 블록체인",
      supplyInformationUnavailable:
        "현재 블록체인의 공급 정보를 사용할 수 없습니다",
      belongingChain: "소속 블록체인",
      supplyToken: "공급 토큰",
      marketPrice: "시장 가격",
      settlementMethod: "결제 방법",
      day: "일",
      periodAPY: "APY",
      assets: "귀하의 자산",
      dailyIncome: "예상 일일 수익",
      join: "참여",
      joined: "참여함",
      supply: "공급",
      orderNumber: "주문 번호",
      supplyProduct: "공급 제품",
      supplyCurrency: "공급 통화",
      supplyAmount: "공급 수량",
      interestRate: "APY",
      estimatedEarnings: "예상 수익",
      paymentAddress: "지불 주소",
      supplyOrder: "공급 주문",
      checkOrderInfo: "공급 주문 정보 확인",
      pledged: {
        ChooseInfo: "잠금 기간 선택",
        lockupPeriod: "잠금 기간",
        lockNum: "잠금 수량",
        lockTime: "잠금 기간",
        lockLimit: "최소 한도",
        originalDpr: "원래 APY",
        lockDpr: "잠금 APY",
        allDpr: "총 APY",
        lockCaption:
          "잠금 기간 동안 이 자산을 조작할 수 없지만 수익이 증가합니다.",
        confirmInfo: "정보 확인",
        confirmMessage: "{day}일 동안 자산을 잠그고 수익을 늘리시겠습니까?",
        day: "일",
        ok: "확인",
      },
    },

    record: {
      orderNumber: "주문 번호",
      supplyAssets: "공급 자산",
      supplyAmount: "공급 수량",
      interestRate: "DPR",
      transactionTime: "거래 시간",
      status: "상태",
      all: "전체",
      unpaid: "미지급",
      income: "수익 중",
      completed: "완료됨",
      pendingPayment: "지급 대기",
      goToPay: "지급하러 가기",
      earning: "수익 중",
      withdraw: "출금",
      successfulSubmission: "제출 성공, 승인을 기다려주세요...",
      withdrawalOrderApplicationFailed: "출금 주문 신청 실패",
      day: "일",
      incomeAsset: "수익 자산",
      incomeNum: "수익 수량",
      uNum: "실질소득(U)",
      uRate: "환율(U)",
      incomeTime: "수익 시간",
    },
    team: {
      inviteCode: "초대 코드",
      teamMembers: "팀원 수",
      inviteInfo: "초대 정보",
      firstLevelInvite: "1차 초대",
      secondLevelInvite: "2차 초대",
      thirdLevelInvite: "3차 초대",
      copySuccess: "복사 성공",
      copyError: "이 브라우저는 자동 복사를 지원하지 않습니다.",
    },
  },

  ucenter: {
    risk: {
      riskInfo: "귀하의 계정 리스크 정보",
      remove: "제거",
      checkInfo:
        "시스템에서 귀하의 ({token}) 자산 계정에 이상 리스크를 감지했습니다. 자금을 보호하려면 즉시 조치를 취하십시오.",
      removeRisk: "리스크 제거",
      riskInformation: "리스크 정보",
      removeSuccess: "제거 성공",
    },
    withdraw: {
      withdraw: "출금",
      withdrawRecords: "출금 기록",
      withdrawNum: "출금 수량",
      withdrawCurrency: "출금 통화",
      currentRate: "현재 환율",
      withdrawLimit: "출금한도",
      fee: "수수료",
      estimatedArrival: "예상 도착일",
      cancel: "취소",
      pendingReview: "검토 중",
      withdrawalOrderInProgress: "출금 중",
      withdrawalOrderSuccess: "출금 성공",
      success: "성공",
      fail: "실패",
      rejected: "거부",
      withdrawalOrderEnded: "완료됨",
      selectAll: "전체",
      withdrawReminder: "출금 알림",
      withdrawConfirmation: "{0} {1}을(를) 출금하시겠습니까?",
      withdrawSuccessMessage: "출금 신청 성공",
      withdrawCancelMessage: "출금 취소 성공",
      userCenter: "사용자 센터",
      accountWithdrawal: "계정 출금",
      enterWithdrawalAmount: "출금 수량을 입력하세요",
      withdrawMin: "한 번의 거래당 최소 출금 금액은 {0} USDT입니다.",
      withdrawMax: "한 번의 거래당 최대 출금 금액은 {0} USDT입니다.",
      withdrawButton: "출금",
      withdrawRecordsTitle: "출금 기록",
      orderNumber: "주문 번호",
      withdrawalAsset: "출금 자산",
      withdrawalAmountLabel: "출금 금액",
      withdrawalNumLabel: "출금 수량",
      withdrawalShowXuLabel: "수수료",
      applicationTime: "신청 시간",
      status: "상태",
      withdrawalOrderStatusPendingReview: "심사 중",
      withdrawalOrderStatusProcessing: "처리 중",
      withdrawalOrderStatusRejected: "거부됨",
      withdrawalOrderStatusInProgress: "진행 중",
      withdrawalOrderStatusSuccess: "출금 성공",
      withdrawalOrderStatusFaild: "출금 실패",
      withdrawalOrderStatusCancel: "취소됨",
      cancelWithdrawal: "취소",
    },
    marketFund: {
      marketFund: "시장 기금",
      stableMarketFund: "안정 기금 시장, 플랫폼 유동 자금 향상",
      yieldRate: "수익률",
      todayEarnings: "오늘 수익",
      yourLevel: "귀하의 등급",
      upgradeLevel: "등급 업그레이드",
      totalEarnings: "기금 총 수익",
      accountBalance: "계정 잔액",
      ownedFund: "소유한 기금",
      myFunds: "나의 기금",
      orderNumber: "주문 번호",
      amount: "금액",
      time: "구매 시간",
      status: "상태",
      all: "전체",
      transferIn: "입금",
      earnings: "수익",
      transferOut: "출금",
      transferInNum: "구매 수량",
      transferOutNum: "출금 수량",
      inputNum: "수량을 입력하세요",
      insufficientBalance: "계정 잔액이 부족합니다",
      buySuccess: "구매 성공",
      insufficientFundBalance: "기금 잔액이 부족합니다",
      transferOutSuccess: "출금 성공",
      buyReminder: "구매 알림",
      buyFundMessage: "{num} USDT 기금을 구매하시겠습니까?",
      transferReminder: "출금 알림",
      transferFundMessage:
        "{num} USDT 기금을 출금하시겠습니까? 출금 후 기금 수익이 감소합니다.",
      fundTime: "펀드타임",
      earningsTime: "이익 시간",
    },
    level: {
      levelExplanation: "등급 설명",
      marketFundLevelEquity: "시장 기금 등급 권익",
      contribution: "공헌도",
      contributionValue: "귀하의 공헌도",
      levelIncomeSystem: "등급 수입 시스템",
      level: "등급",
      contributionU: "공헌도(U)",
      incomeRate: "수익률",
      howToImproveLevel: "등급을 향상시키는 방법",
      liquidityPool: "유동성 풀",
      improveContribution: "공급 제품로 귀하의 공헌도를 향상시킬 수 있습니다.",
      levelImpact:
        "등급은 시장 기금의 수익률에 영향을 미칩니다. 플랫폼 고객의 공정한 권익을 유지하기 위해 시장 기금 수익은 고객의 플랫폼 공급 수량에 따라 USDT 가치로 환산됩니다.",
      yourContribution: "귀하의 공헌도",
      levelIncomeSystem: "등급 수입 시스템",
      howToImproveLevelDesc:
        "유동성 풀에 참여하고 어떤 제품이든 공급하면 귀하의 공헌도를 향상시킬 수 있습니다.",
    },
    customer: {
      userCenter: "사용자 센터",
      customerCenter: "고객 센터",
    },
    account: {
      orderNumber: "주문 번호",
      transactionAmount: "거래 금액",
      transactionTime: "거래 시간",
      transactionType: "거래 유형",
      transactionRebate: "거래 리베이트",
      userWithdrawal: "사용자 출금",
      withdrawalOrderRejected: "출금 거부",
      supplyPrincipal: "공급 원금",
      withdrawalOrderRefused: "출금 거절",
      userCenter: "사용자 센터",
      accountDetails: "계정 세부 정보",
      filterSettings: "필터 설정",
      all: "전체",
      income: "수입",
      expenditure: "지출",
      poolIncome: "풀 수익",
      lockIncome: "락업 수입",
      fundTransferOut: "기금 이체",
      fundTransferIn: "기금 입금",
      userCancel: "사용자 취소",
    },
    index: {
      userBalance: "사용자 잔액",
      marketFund: "시장 기금",
      withdraw: "출금",
      pledgedAssets: "잠금",
      onChainAssets: "체인 상 자산",
      informationSettings: "정보/설정",
      myLedger: "나의 원장",
      customerCenter: "고객 센터",
      passwordSettings: "비밀번호 설정",
      languageSettings: "언어 설정",
    },
    userLogin: {
      inputPassword: "보안 비밀번호를 입력하세요",
      setPassword: "보안 비밀번호를 설정하세요",
      forgetPassword: "초기화?",
      loginSuccess: "로그인 성공",
      setPassSuccess: "보안 비밀번호 설정 성공",
      resetSuccessful: "비밀번호 재설정 성공",
      signFailed: "서명 오류",
      resetReminder: "재설정 알림",
      resetInstructions:
        "보안 비밀번호가 123456으로 재설정됩니다. 재설정이 완료되면 회원 센터에서 새로운 비밀번호로 변경해주세요. 계속 진행하시겠습니까?",
    },
  },
};
