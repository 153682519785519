<template>
  <v-bottom-navigation v-model="value" grow color="primary">
    <v-btn class="b-btn" to="/">
      <span>{{ $t("foot.home") }}</span>

      <v-icon>mdi-home-minus-outline</v-icon>
    </v-btn>

    <v-btn class="b-btn" to="/swap">
      <span>{{ $t("foot.swap") }}</span>

      <v-icon>mdi-order-bool-ascending-variant</v-icon>
    </v-btn>

    <v-btn class="b-btn" to="/borrow">
      <span>{{ $t("foot.borrow") }}</span>

      <v-icon>mdi-hand-coin-outline</v-icon>
    </v-btn>

    <v-btn class="b-btn" to="/pool">
      <span>{{ $t("foot.pool") }}</span>

      <v-icon> mdi-account-hard-hat-outline</v-icon>
    </v-btn>

    <v-btn class="b-btn" to="/ucenter">
      <span>{{ $t("foot.ucenter") }}</span>

      <v-icon>mdi-account-circle-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "Foot",
  data: () => ({ value: 0 }),
  methods: {},
};
</script>
<style lang="scss" scoped>
.b-btn {
  height: 100% !important;
}

.theme--dark.v-bottom-navigation {
  background-color: #192869 !important;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: transparent;
}

.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #777777 !important;
}
</style>
