import Dialog from "@/components/extend/dialog.vue";
import vuetify from "./vuetify";
import i18n from "@/locale";

const DialogPlugin = {
  install(Vue) {
    Vue.prototype.$dialog = {
      show(options) {
        if (!options.hasOwnProperty("showCancelButton"))
          options.showCancelButton = true;
        return new Promise((resolve, reject) => {
          const dialogComponent = new Vue({
            i18n,
            vuetify,
            render: (h) =>
              h(Dialog, {
                props: {
                  options,
                  resolve,
                  reject,
                },
              }),
          }).$mount();

          const dialogContainer = document.createElement("div");
          document.body.appendChild(dialogContainer);
          dialogContainer.appendChild(dialogComponent.$el);
        });
      },
    };
  },
};

export default DialogPlugin;
