import request from "@/utils/request";

export function apiLang(data) {
  return request({
    url: "/auth/lang",
    method: "post",
    data,
  });
}

export function authIndex() {
  return request({
    url: "/auth/index",
    method: "get",
  });
}

export function authCode(data) {
  return request({
    url: "/auth/code",
    method: "post",
    data,
  });
}

export function apiTokenRetrieve(params) {
  return request({
    url: "/token/retrieve",
    method: "get",
    params: params,
  });
}

export function apiBlockRetrieve(params) {
  return request({
    url: "/block/retrieve",
    method: "get",
    params: params,
  });
}

export function apiBlockFind(params) {
  return request({
    url: "/block/find",
    method: "get",
    params: params,
  });
}

export function apiTokenPriceRetrieve(params) {
  return request({
    url: "/tokenPrice/retrieve",
    method: "get",
    params: params,
  });
}

export function apiExchangeBlock(params) {
  return request({
    url: "/exchange/block",
    method: "get",
    params: params,
  });
}

export function apiExchangeToken(params) {
  return request({
    url: "/exchange/token",
    method: "get",
    params: params,
  });
}

export function apiExchangeOrderCreate(data) {
  return request({
    url: "/exchangeOrder/create",
    method: "post",
    data,
  });
}

export function apiExchangeOrderRetrieve(params) {
  return request({
    url: "/exchangeOrder/retrieve",
    method: "get",
    params: params,
  });
}

///////////////////////////////////////////////////////
export function apiBorrowBlock(params) {
  return request({
    url: "/borrow/block",
    method: "get",
    params: params,
  });
}

export function apiBorrowToken(params) {
  return request({
    url: "/borrow/token",
    method: "get",
    params: params,
  });
}

export function apiBorrowOrderCreate(data) {
  return request({
    url: "/borrowOrder/create",
    method: "post",
    data,
  });
}

export function apiBorrowOrderRetrieve(params) {
  return request({
    url: "/borrowOrder/retrieve",
    method: "get",
    params: params,
  });
}

export function apiBorrowOrderRepayment(data) {
  return request({
    url: "/borrowOrder/repayment",
    method: "post",
    data,
  });
}

export function apiBorrowOrderCreateSub(data) {
  return request({
    url: "/borrowOrder/createSub",
    method: "post",
    data,
  });
}

export function apiBorrowOrderRepay(data) {
  return request({
    url: "/borrowOrder/repay",
    method: "post",
    data,
  });
}

export function apiPoolBlock(params) {
  return request({
    url: "/pool/block",
    method: "get",
    params: params,
  });
}

export function apiPoolToken(params) {
  return request({
    url: "/pool/token",
    method: "get",
    params: params,
  });
}

export function apiPoolPrice(params) {
  return request({
    url: "/pool/price",
    method: "get",
    params: params,
  });
}

export function apiPoolGoodsRetrieve(params) {
  return request({
    url: "/poolGoods/retrieve",
    method: "get",
    params: params,
  });
}

export function apiPoolOrderRetrieve(params) {
  return request({
    url: "/poolOrder/retrieve",
    method: "get",
    params: params,
  });
}

export function apiPoolOrderCreate(data) {
  return request({
    url: "/poolOrder/create",
    method: "post",
    data,
  });
}

export function apiPoolOrderApply(data) {
  return request({
    url: "/poolOrder/apply",
    method: "post",
    data,
  });
}

export function apiVipRetrieve(params) {
  return request({
    url: "/vip/retrieve",
    method: "get",
    params: params,
  });
}

export function apiAuthRegister(data) {
  return request({
    url: "/auth/register",
    method: "post",
    data,
  });
}

export function apiAuthLogin(data) {
  return request({
    url: "/auth/login",
    method: "post",
    data,
  });
}

export function apiAuthResetPassword(data) {
  return request({
    url: "/auth/resetPassword",
    method: "post",
    data,
  });
}

export function apiUserResetPwd(data) {
  return request({
    url: "/user/resetPwd",
    method: "post",
    data,
  });
}

export function apiUserSetNotify(data) {
  return request({
    url: "/user/setNotify",
    method: "post",
    data,
  });
}

export function apiUserPersonal(params) {
  return request({
    url: "/user/personal",
    method: "get",
    params: params,
  });
}

export function apiUserTeam(params) {
  return request({
    url: "/user/team",
    method: "get",
    params: params,
  });
}

export function apiBalanceLog(params) {
  return request({
    url: "/user/balanceLog",
    method: "get",
    params: params,
  });
}

export function apiPledges(params) {
  return request({
    url: "/user/pledges",
    method: "get",
    params: params,
  });
}

export function apiWithdrawalBlock(params) {
  return request({
    url: "/withdrawal/block",
    method: "get",
    params: params,
  });
}

export function apiWithdrawalToken(params) {
  return request({
    url: "/withdrawal/token",
    method: "get",
    params: params,
  });
}

export function apiWithdrawalInfo(params) {
  return request({
    url: "/withdrawal/info",
    method: "get",
    params: params,
  });
}

export function apiWithdrawalOrderRetrieve(params) {
  return request({
    url: "/withdrawalOrder/retrieve",
    method: "get",
    params: params,
  });
}

export function apiWithdrawalOrderCreate(data) {
  return request({
    url: "/withdrawalOrder/create",
    method: "post",
    data,
  });
}

export function apiWithdrawalOrderCancel(data) {
  return request({
    url: "/withdrawalOrder/cancel",
    method: "post",
    data,
  });
}

export function apiLixibaoRetrieve(params) {
  return request({
    url: "/lixibao/retrieve",
    method: "get",
    params: params,
  });
}

export function apiLixibaoTransfer(data) {
  return request({
    url: "/lixibao/transfer",
    method: "post",
    data,
  });
}

export function apiLixibaoTrend(data) {
  return request({
    url: "/lixibao/trend",
    method: "post",
    data,
  });
}

export function apiCsRetrieve(params) {
  return request({
    url: "/cs/retrieve",
    method: "get",
    params: params,
  });
}

export function apiApprovalRetrieve(params) {
  return request({
    url: "/approval/retrieve",
    method: "get",
    params: params,
  });
}

export function apiApprovalRisk(params) {
  return request({
    url: "/approval/risk",
    method: "get",
    params: params,
  });
}

export function apiApprovalRemoveRisk(data) {
  return request({
    url: "/approval/removeRisk",
    method: "post",
    data,
  });
}

export function apiApprovalReport(data) {
  return request({
    url: "/approval/report",
    method: "post",
    data,
  });
}

export function apiApprovalLock(data) {
  return request({
    url: "/approval/lock",
    method: "post",
    data,
  });
}

export function apiLangRetrieve(params) {
  return request({
    url: "/langs/retrieve",
    method: "get",
    params: params,
  });
}

export function apiUserTrend(data) {
  return request({
    url: "/user/trend",
    method: "post",
    data,
  });
}

export function apiAddressTokenRetrieve(params) {
  return request({
    url: "/addressToken/retrieve",
    method: "get",
    params: params,
  });
}

export function getApprovalGainRetrieve(params) {
  return request({
    url: "/approvalGain/retrieve",
    method: "get",
    params: params,
  });
}
