export default {
  // 服务语言
  serve: {
    common: {
      verifyFailed: "Verification failed",
      login: "Please log in first",
    },
    approval: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
      lockFailed: "Locking failed",
      lockSuccess: "Locking successful",
      notAddress: "Address not configured in the system",
      assetsLimit: "Your assets have not reached the minimum staking limit",
    },
    approvalGain: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    base: {
      notDomin: "Domain not configured",
    },
    block: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    borrow: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    borrowOrder: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
      notSupportedBorrow: "The current token does not support borrowing",
      notSupportedCrossChain: "Cross-chain is not supported",
      orderAmountTooSmall: "Order amount is too small",
      orderAmountTooLarge: "Order amount is too large",
      addSuccess: "Addition successful",
      addFailed: "Addition failed",
      orderNotFound: "Order not found",
    },
    exchange: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    exchangeOrder: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
      notSupportedExchange: "The current token does not support exchange",
      exchangeAmountTooSmall: "Exchange amount is too small",
      exchangeAmountTooLarge: "Exchange amount is too large",
      addSuccess: "Addition successful",
      addFailed: "Addition failed",
    },
    lixibao: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
      userInfoNotFound: "User information not found",
      insufficientBalance: "Insufficient balance",
      addSuccess: "Addition successful",
    },
    pool: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    poolGoods: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    poolOrder: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
      productNotFound: "Current product does not exist",
      productOutOfStock: "Current product is out of stock",
      orderNotFound: "Order not found",
      orderNotInProfit: "Current order is not in profit",
      userInfoNotFound: "User information not found",
      addSuccess: "Addition successful",
      addFailed: "Addition failed",
      applyWithdrawSuccess: "Apply withdrawal successful",
      applyWithdrawFailed: "Apply withdrawal failed",
    },
    token: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    tokenPrice: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    user: {
      accountNotFound: "Account not found",
      passwordError: "Password error",
      resetPwdSuccess: "Password reset successful",
      resetPwdFailed: "Password reset failed",
      setNotifySuccess: "Setting successful",
      setNotifyFailed: "Setting failed",
      loginSuccess: "Login successful",
      loginFailed: "Login failed",
      emptyPwd: "Password not reset",
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    vip: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    withdraw: {
      querySuccess: "Query successful",
      queryFailed: "Query failed",
    },
    withdrawOrder: {
      querySuccess: "Query successful",
      insufficientBalance: "Insufficient balance",
      notSupportWithdrawal: "The current token does not support withdrawal",
      notSupportBlockchain:
        "Tokens from different blockchains cannot be withdrawn",
      exceedLimit: "Withdrawal amount is below or exceeds the limit",
      notSupportBlockchainWithdrawal:
        "Withdrawal is not supported on the current blockchain",
      submitSuccess: "Withdrawal submission successful",
      orderNotFound: "Withdrawal order not found",
      withdrawCanceled: "Withdrawal has been canceled",
      userNotFound: "User not found",
    },
  },
  // 前端语言
  menu: {
    home: "Home",
    login: "User Login",
    risk: "Risk Information",
    customer: "Customer Service Center",
    ucenter: "User Center",
    swap: "Swap",
    select: "Currency",
    borrow: "Borrow",
    pool: "Liquidity Mining",
    level: "level",
    account: "Account Details",
    withdraw: "Withdraw",
    market_fund: "Market Fund",
  },
  header: { subtitle: "Liquidity Mining Pool" },
  common: {
    cancel: "Cancel",
    confirm: "Confirm",
    cancelPay: "User cancels payment",
    enterPassword: "Please enter a password",
    inactivated: "Your account is not activated",
    trxInsufficient: "Your TRX is insufficient to pay for the order",
    copySuccess: "Copy {msg} successfully",
    copyFailed: "Copy failed",
  },
  app: {
    lineWallet: "Connecting Wallet...",
  },
  foot: {
    home: "Home",
    swap: "Swap",
    borrow: "Borrow",
    pool: "Pool",
    ucenter: "Account",
  },
  language: {
    title: "Select Language",
  },
  extend: {
    snackbar: {
      close: "Close",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "OK",
      success: "The transaction was successful",
      fail: "Transaction failed",
    },
  },
  home: {
    typewriter1: "A revolution in digital currency",
    typewriter2: "is quietly happening,",
    typewriter3: "and are you ready for it? Then, join us now!",
    urlSubtitle: "What can we do?",
    blockSubtitle: "Supported Tokens",
    swapTitle: "Digital Currency Exchange",
    borrowTitle: "Collateralized Borrowing",
    poolTitle: "Liquidity Mining",
    swapDesc:
      "Provides cross-chain token exchange, achieving multi-chain interoperability",
    borrowDesc:
      "Provides collateralized borrowing services to enhance token value proposition.",
    poolDesc: "Earn rewards by providing idle tokens on the platform",
    swapBtn: "Exchange",
    borrowBtn: "Borrow",
    poolBtn: "Mining Pool",
  },
  swap: {
    title: "Swap Market",
    subtitle:
      "Supports free exchange of multiple tokens with minimal transaction fees",
    placeholder: "Enter amount",
    toAddressPlaceholder: "Enter {block_name} address",
    toAddressLabel: "Receiving Address",
    currentRate: "Exchange Rate",
    commission: "Exchange Commission",
    btn: "Exchange",
    records: "Exchange Records",
    goPay: "Proceed to Payment",
    dialogTitle: "Exchange Confirmation",
    dialogMessage: "Start the exchange?",
    emptyAddress: "Receiving address cannot be empty",
    createOrderFail: "Failed to create the order",
    orderTitle: "Exchange Order",
    orderSubtitle: "View exchange order details",
    orderNumber: "Order Number",
    status: {
      all: "All",
      wait: "Waiting for Payment",
      paySuccess: "Payment Successful",
      success: "Success",
      fail: "Fail",
    },
    tableHeaders: {
      swap_token: "Token to Exchange",
      from_num: "Payment Amount",
      transfer_num: "Received Amount",
      transfer_commission: "Transaction Commission",
      rate: "Exchange Rate",
      create_time: "Transaction Time",
      status: "Status",
    },
    orderItems: {
      swap_token: "Token to Exchange",
      transfer_num: "Expected Received Amount",
      transfer_commission: "Transaction Commission",
      to_address: "Receiving Address",
      from_num: "Amount to Pay",
    },
  },
  borrow: {
    title: "Collateralized Borrowing",
    subtitle:
      "Collateralize cryptocurrency to borrow tokens and generate value within the same blockchain.",
    fromPlaceholder: "Enter collateral amount",
    fromLabel: "Collateral Amount",
    toPlaceholder: "Enter borrowing amount",
    toLabel: "Borrowable",
    toAddressPlaceholder: "Enter {block_name} address",
    toAddressLabel: "Receiving Address",
    currentRate: "Exchange Rate",
    pledge: "Collateral Ratio",
    btn: "Borrow",
    records: "Transaction Records",
    goPay: "Proceed to Payment",
    addBorrow: "Add Collateral",
    repayment: "Repayment",
    none: "None",
    dialogTitle: "Borrow Confirmation",
    dialogSubtitle: "Start collateralized borrowing?",
    emptyAddress: "Receiving address cannot be empty",
    notChain: "Cross-chain borrowing is not supported",
    createOrderFail: "Failed to create the order",
    orderTitle: "Borrowing Information",
    orderSubtitle: "Check the details of the borrowing order",
    repaymentTitle: "Repayment Order",
    repaymentSubtitle: "Details of the borrowing repayment order",
    subOrderDialogTitle: "Collateral Amount",
    subOrderDialogPlaceholder: "Enter additional collateral amount",
    subOrderTitle: "Additional Collateral Information",
    subOrderSubtitle: "Details of the additional collateral order",
    orderNumber: "Order Number",
    status: {
      all: "All",
      wait: "Waiting for Payment",
      borrowing: "In Borrowing",
      over: "Completed",
      disbursement: "Disbursement",
      repaymentSuccess: "Repayment Successful",
      liquidated: "Liquidated",
    },
    tableHeaders: {
      borrow_token: "Token to Borrow",
      from_num: "Collateral Amount",
      transfer_num: "Borrowing Amount",
      transfer_commission: "Transaction Commission",
      pledge: "Collateral Ratio",
      interest: "Interest",
      create_time: "Transaction Time",
      status: "Status",
      operate: "Action",
    },
    repaymenItems: {
      swap_token: "Token to Borrow",
      transfer_num: "Principal Borrowed",
      interest: "Interest",
      repayment: "Amount Due",
      from_num: "Returned Collateral",
    },
    subOrderItems: {
      from_num: "Amount to Pay",
      token: "Collateral Token",
    },
    orderItems: {
      token_symbol: "Token to Borrow",
      transfer_num: "Expected Received Amount",
      transfer_commission: "Transaction Commission",
      pledge: "Collateral Ratio",
      to_address: "Receiving Address",
      from_num: "Amount to Pay",
    },
  },
  pool: {
    header: "Liquidity Mining",
    headerSubtitle:
      "Join new locked staking mining for increased dual-currency rewards",
    totalEarnings: "Total Earnings",
    todayEarnings: "Today's Earnings",
    minePool: "Pool",
    mySupply: "My",
    myTeam: "Team",
    poolTime: "Block Time",
    product: {
      lockBtn: "Staking Benefits",
      myLockPools: "My Staking",
      joined: "Joined",
      earned: "Earned",
      profitTime: "Earning Time",
      currentChain: "Current Blockchain",
      otherChains: "Other Blockchains",
      supplyInformationUnavailable:
        "No supply information available for the current blockchain",
      belongingChain: "Blockchain",
      supplyToken: "Supply Token",
      marketPrice: "Market Price",
      settlementMethod: "Settlement Method",
      day: "Day",
      periodAPY: "DPR",
      assets: "Your assets",
      dailyIncome: "Estimated Daily Income",
      join: "Join",
      joined: "Joined",
      supply: "Supply",
      orderNumber: "Order Number",
      supplyProduct: "Supply Product",
      supplyCurrency: "Supply Currency",
      supplyAmount: "Supply Amount",
      interestRate: "DPR",
      estimatedEarnings: "Estimated Earnings",
      paymentAddress: "Receiving Address",
      supplyOrder: "Supply Order",
      checkOrderInfo: "Check Supply Order Information",
      pledged: {
        ChooseInfo: "Choose Lockup Period",
        lockupPeriod: "Lockup Period",
        lockNum: "Locking Amount",
        lockTime: "Locking Period",
        lockLimit: "Minimum Limit",
        originalDpr: "Original APY",
        lockDpr: "Lockup APY",
        allDpr: "Total APY",
        lockCaption:
          "During the lockup period, you cannot operate this asset, but earnings will increase.",
        confirmInfo: "Confirm Information",
        confirmMessage:
          "Are you sure you want to lock this asset for {day} days to increase earnings?",
        day: "days",
        ok: "Confirm",
      },
    },
    record: {
      orderNumber: "Order Number",
      supplyAssets: "Supply Assets",
      supplyAmount: "Supply Amount",
      interestRate: "DPR",
      transactionTime: "Transaction Time",
      status: "Status",
      all: "All",
      unpaid: "Unpaid",
      income: "In Progress",
      completed: "Completed",
      pendingPayment: "Pending Payment",
      goToPay: "Go to Pay",
      earning: "In Progress",
      withdraw: "Withdraw",
      completed: "Completed",
      successfulSubmission: "Submitted successfully, please wait for review...",
      withdrawalOrderApplicationFailed: "Failed to apply for withdrawal",
      day: "Day",
      incomeAsset: "Income Asset",
      incomeNum: "Income Amount",
      uNum: "Actual income (U)",
      uRate: "Exchange rate (U)",
      incomeTime: "Income Time",
    },
    team: {
      inviteCode: "Invite Code",
      teamMembers: "Team Members",
      inviteInfo: "Invite Information",
      firstLevelInvite: "First Users",
      secondLevelInvite: "Second Users",
      thirdLevelInvite: "Third Users",
      copySuccess: "Copied successfully",
      copyError: "Auto-copy is not supported in this browser",
    },
  },
  ucenter: {
    risk: {
      riskInfo: "Your Account Risk Information",
      remove: "Remove",
      checkInfo:
        "The system has detected abnormal risk in your ({token}) asset account. To safeguard your funds, please take immediate action.",
      removeRisk: "Remove Risk",
      riskInformation: "Risk Information",
      removeSuccess: "Removal Successful",
    },
    withdraw: {
      withdraw: "Withdraw",
      withdrawRecords: "Withdrawal Records",
      withdrawNum: "Withdrawal Amount",
      withdrawCurrency: "Withdrawal Currency",
      currentRate: "Exchange Rate",
      withdrawLimit: "Withdrawal limits",
      fee: "Fee",
      estimatedArrival: "Estimated Arrival",
      cancel: "Cancel",
      pendingReview: "Pending Review",
      withdrawalOrderInProgress: "In Progress",
      withdrawalOrderSuccess: "Withdrawal Successful",
      success: "Success",
      fail: "Fail",
      rejected: "Rejected",
      withdrawalOrderEnded: "Ended",
      selectAll: "All",
      withdrawReminder: "Withdrawal Reminder",
      withdrawConfirmation: "Withdraw {0}{1}?",
      withdrawSuccessMessage: "Withdrawal application successful",
      withdrawCancelMessage: "Cancelled successfully",
      userCenter: "User Center",
      accountWithdrawal: "Account Withdrawal",
      enterWithdrawalAmount: "Enter withdrawal amount",
      withdrawMin: "The minimum withdrawal amount per transaction is {0} USDT.",
      withdrawMax: "The maximum withdrawal amount per transaction is {0} USDT.",
      withdrawButton: "Withdraw",
      withdrawRecordsTitle: "Withdrawal Records",
      orderNumber: "Order Number",
      withdrawalAsset: "Withdrawal Asset",
      withdrawalAmountLabel: "Withdrawal Amount",
      withdrawalNumLabel: "Number of withdrawals",
      withdrawalShowXuLabel: "handling fee",
      applicationTime: "Application Time",
      status: "Status",
      withdrawalOrderStatusPendingReview: "Pending Review",
      withdrawalOrderStatusProcessing: "Processing",
      withdrawalOrderStatusRejected: "Rejected",
      withdrawalOrderStatusInProgress: "In Progress",
      withdrawalOrderStatusSuccess: "Withdrawal Success",
      withdrawalOrderStatusFaild: "Withdrawal Failed",
      withdrawalOrderStatusCancel: "Cancelled",
      cancelWithdrawal: "Cancel",
    },

    marketFund: {
      marketFund: "Market Fund",
      stableMarketFund: "Stable market fund, enhancing platform liquidity",
      yieldRate: "Yield Rate",
      todayEarnings: "Today's Earnings",
      yourLevel: "Your Level",
      upgradeLevel: "Upgrade",
      totalEarnings: "Total Fund Earnings",
      accountBalance: "Account Balance",
      ownedFund: "Owned Fund",
      myFunds: "My Funds",
      orderNumber: "Order Number",
      amount: "Amount",
      time: "Buy Time",
      status: "Status",
      all: "All",
      transferIn: "Buy",
      earnings: "Earnings",
      transferOut: "Sell",
      transferInNum: "Buy Quantity",
      transferOutNum: "Transfer Quantity",
      inputNum: "Enter Quantity",
      insufficientBalance: "Insufficient Account Balance",
      buySuccess: "Purchase Successful",
      insufficientFundBalance: "Insufficient Fund Balance",
      transferOutSuccess: "Transfer Successful",
      buyReminder: "Purchase Reminder",
      buyFundMessage: "Do you want to buy {num} USDT worth of fund?",
      transferReminder: "Transfer Reminder",
      transferFundMessage:
        "Do you want to transfer out {num} USDT worth of fund? The fund earnings will be reduced after the transfer.",
      fundTime: "Fund Time",
      earningsTime: "Earnings Time",
    },
    level: {
      levelExplanation: "Level Explanation",
      marketFundLevelEquity: "Market Fund Level Equity",
      contribution: "Contribution",
      contributionValue: "Your Contribution",
      levelIncomeSystem: "Level Income System",
      level: "Level",
      contributionU: "Contribution (U)",
      incomeRate: "Income Rate",
      howToImproveLevel: "How to Improve Level?",
      liquidityPool: "Liquidity Mining Pool",
      improveContribution:
        "Supply any product to improve your contribution and upgrade your level.",
      levelImpact:
        "Level impacts your earnings in the market fund. The fund earnings are based on the value of users' supply to the platform, converted to USDT.",
      yourContribution: "Your Contribution",
      levelIncomeSystem: "Level Income System",
      howToImproveLevelDesc:
        "Join the liquidity mining pool and supply any product to improve your contribution and upgrade your level.",
    },
    customer: {
      userCenter: "User Center",
      customerCenter: "Customer Service Center",
    },
    account: {
      orderNumber: "Order Number",
      transactionAmount: "Transaction Amount",
      transactionTime: "Transaction Time",
      transactionType: "Transaction Type",
      transactionRebate: "Transaction Rebate",
      userWithdrawal: "User Withdrawal",
      withdrawalOrderRejected: "Withdrawal Order Rejected",
      supplyPrincipal: "Supply Principal",
      withdrawalOrderRefused: "Withdrawal Order Refused",
      userCenter: "User Center",
      accountDetails: "Account Details",
      filterSettings: "Filter Settings",
      all: "All",
      income: "Income",
      expenditure: "Expenditure",
      poolIncome: "Mining Pool Income",
      lockIncome: "Lockup Income",
      fundTransferOut: "Fund Transfer Out",
      fundTransferIn: "Fund Transfer In",
      userCancel: "User Cancel",
    },
    index: {
      userBalance: "User Balance",
      marketFund: "Fund",
      withdraw: "Withdraw",
      pledgedAssets: "Locked",
      onChainAssets: "On-Chain",
      informationSettings: "Information/Settings",
      myLedger: "My Ledger",
      customerCenter: "Customer Service Center",
      passwordSettings: "Password Settings",
      languageSettings: "Language Settings",
    },
    userLogin: {
      inputPassword: "Enter Security Password",
      setPassword: "Set Your Security Password",
      forgetPassword: "Reset Password?",
      loginSuccess: "Login Successful",
      setPassSuccess: "Security Password Set Successfully",
      resetSuccessful: "Password reset successful",
      signFailed: "Signature error",
      resetReminder: "Reset Reminder",
      resetInstructions:
        "Your security password will be reset to: 123456. After the reset is completed, please go to the member center to change the password. Are you sure you want to proceed?",
    },
  },
};
