<template>
  <v-app>
    <Header />
    <v-main app>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer padless app>
      <Foot />
    </v-footer>

    <v-dialog v-model="showProgress" persistent>
      <v-card flat class="pt-2">
        <v-card-text>
          {{ $t("app.lineWallet") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <UserLogin :isShow="true"></UserLogin> -->
  </v-app>
</template>

<script>
// import UserLogin from "./components/UserLogin.vue";
import Header from "./components/Header.vue";
import Foot from "./components/Foot.vue";
const { TrcApi } = require("@/utils/trc");
const { ErcApi } = require("@/utils/erc");
import { apiLang } from "@/api/api";

import { mapState } from "vuex";
import { getLocalStorage, setLocalStorage } from "@/utils/local-storage";
export default {
  name: "App",
  components: { Header, Foot },
  data: () => ({
    showProgress: false,
  }),
  async mounted() {
    this.getAddress();
    this.Inval = setInterval(this.getAddress, 5000);
  },

  async created() {
    let invitationCode = this.$route.query.invitationCode;
    if (
      (invitationCode != null) &
      (invitationCode != "") &
      (invitationCode != undefined)
    ) {
      this.$store.commit("globals/setInvitationCode", invitationCode);
    }
    const { language } = getLocalStorage("language");
    if (language === null) {
      setLocalStorage({ language: "en" });
      await this.getLang(invitationCode);
    }
    // invitationCode = "QNFER"
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
      console.log("#########1111111111111#########");
      if (this.token === null || this.token === "") {
        this.$store.commit("globals/clearInfo", {});
      }
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  computed: {
    ...mapState("globals", {
      chainId: "chainId",
      address: "address",
      blockType: "blockType",
      invitationCode: "invitationCode",
      token: "token",
    }),
  },
  methods: {
    async getLang(invitationCode) {
      let data = {
        invitationCode: invitationCode,
      };
      const result = await apiLang(data);
      this.$vuetify.lang.current = result.lang.locale;
      this.$i18n.locale = result.lang.locale;
      setLocalStorage({ language: result.lang.locale });
    },
    async getAddress() {
      try {
        let address = "";
        let blockType = null;
        let chainId = 0;
        if (window.tronWeb) {
          blockType = 0;
          address = await TrcApi.getAddress();
          chainId = 99999999;
        } else if (window.ethereum) {
          blockType = 1;
          address = await ErcApi.getAddress();
          chainId = await ErcApi.getNetId();
        }
        console.log("blockType=>", blockType);
        console.log("chainId=>", chainId);
        console.log("address=>", address);
        if (!address) {
          this.showProgress = true;
          return;
        }
        this.showProgress = false;
        // 全部相等
        if (
          this.address === address &&
          this.blockType === blockType &&
          this.chainId === chainId
        ) {
          return;
        }

        // 注册
        await this.$store.dispatch("globals/authRegister", {
          address: address,
          blockType: blockType,
          chainId: chainId,
          invitationCode: this.invitationCode,
        });
        // clearInterval(this.Inval);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: system-ui, -apple-system, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #9e9e9e;
  background: #212e67;
  background-image: repeating-linear-gradient(
    45deg,
    #192869,
    #212e67 250px,
    transparent 0,
    transparent 30px
  );
}

.v-input__slot {
  min-height: 36px !important;
  font-size: 14px;
}

.v-data-table__mobile-row__header {
  font-weight: 400 !important;
}
.v-data-table__mobile-row__cell {
  font-size: 0.8125rem !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: transparent !important;
}

.v-application--is-ltr .v-data-table__mobile-row__cell {
  color: #9e9e9e !important;
}

.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: #ffc107 !important;
}

// .v-snack__wrapper.theme--dark{
//   background-color: #03a9f4 !important;
// }

.box-card {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.theme--dark.v-pagination .v-pagination__item--active {
  color: #0380e0 !important;
}
.theme--dark.v-pagination .v-pagination__item {
  background-color: #01579b !important;
}

.theme--dark.v-pagination .v-pagination__navigation {
  background-color: #01579b !important;
}
</style>
