const Web3 = require("web3");
const erc_json = require("@/utils/ERC20.json");
const abi = erc_json.abi;
const web3 = new Web3(Web3.givenProvider);
//const ethers = require('ethers');
//api节点链接，这里我填的是BSC测试网的api地址
//var web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
//web3位数对照表
const fromWei = {
  0: "noether",
  1: "wei",
  3: "kwei",
  6: "mwei",
  9: "gwei",
  12: "szabo",
  15: "finney",
  18: "ether",
  21: "kether",
  24: "mether",
  27: "gether",
  30: "tether",
};
export const ErcApi = {
  async getAddress() {
    try {
      let accounts = await web3.eth.getAccounts();
      if (accounts && accounts.length > 0) {
        return accounts[0];
      }
      const addr = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (addr) return addr[0];
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // 签名消息,验证账户所有权
  async resetPassword() {
    let account = await this.getAddress();
    try {
      const signInfo = await web3.eth.personal.sign(
        "The reset password is: 123456, please go to <me> to change the password after the reset is completed.",
        account
      );
      return signInfo;
    } catch (error) {
      return false;
    }
  },

  //查询钱包余额
  async getBalance(precision) {
    // 获取当前账号
    let account = await web3.eth.getAccounts();
    let balance = await web3.eth.getBalance(account[0]);
    const amount = web3.utils.fromWei(balance, fromWei[precision]);
    return amount;
  },

  async getNetId() {
    return await web3.eth.net.getId();
  },

  //trx转账交易
  async sendNative(to_address, amount, from_address) {
    let gasPrice = await web3.eth.getGasPrice();
    gasPrice = (parseInt(gasPrice) * 1.1).toFixed(0).toString();
    let value = web3.utils.toWei(amount.toString(), "ether");
    var message = {
      from: from_address,
      to: to_address,
      value: value,
      gasPrice: gasPrice,
    };
    try {
      await web3.eth.sendTransaction(message, (err, res) => {
        var output = "";
        if (!err) {
          output += res;
          this.txHash = res;
        } else {
          output = "Error" + err;
        }
      });

      return { isOk: true, msg: "success" };
    } catch (error) {
      if (error.code == 4001) return { isOk: false, msg: "common.cancelPay" };
      return { isOk: false, msg: error };
    }
  },

  async approval(
    address,
    contract,
    spender_address,
    amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  ) {
    let gasPrice = await web3.eth.getGasPrice();

    gasPrice = (parseInt(gasPrice) * 2).toFixed(0).toString();
    const web3Contract = new web3.eth.Contract(abi, contract);
    try {
      const transactionHash = await web3Contract.methods
        .approve(spender_address, amount)
        .send({ from: address, gasPrice: gasPrice });
      return { isOk: true, msg: "success" };
    } catch (error) {
      console.log("error", error);
      if (error.code == 4001) return { isOk: false, msg: "common.cancelPay" };
      return { isOk: false, msg: error };
    }
  },

  async transfer(address, contract, spender_address, amount, precision) {
    let gasPrice = await web3.eth.getGasPrice();
    gasPrice = (parseInt(gasPrice) * 2).toFixed(0).toString();
    const web3Contract = new web3.eth.Contract(abi, contract);
    const _amount = web3.utils.toWei(String(amount), fromWei[precision]);
    try {
      const transaction = await web3Contract.methods
        .transfer(spender_address, _amount)
        .send({ from: address, gasPrice: gasPrice });
      return { isOk: true, msg: "success", hash_id: transaction["blockHash"] };
    } catch (error) {
      if (error.code == 4001) return { isOk: false, msg: "common.cancelPay" };
      return { isOk: false, msg: error };
    }
  },

  async balanceOf(contract, address, precision) {
    const web3Contract = new web3.eth.Contract(abi, contract);
    const amount = await web3Contract.methods.balanceOf(address).call();
    const balanceOf = web3.utils.fromWei(amount, fromWei[precision]);
    return balanceOf;
  },
};
