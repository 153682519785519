import payInput from "@/components/pay_order/payInput";
import vuetify from "./vuetify";
import i18n from "@/locale";

const payInputPlugin = {
  install(Vue) {
    Vue.prototype.$payInputDialog = {
      show(options) {
        if (!options.hasOwnProperty("showCancelButton"))
          options.showCancelButton = true;
        return new Promise((resolve, reject) => {
          const payInputComponent = new Vue({
            i18n,
            vuetify,
            render: (h) =>
              h(payInput, {
                props: {
                  options,
                  resolve,
                  reject,
                },
              }),
          }).$mount();

          const payInputContainer = document.createElement("div");
          document.body.appendChild(payInputContainer);
          payInputContainer.appendChild(payInputComponent.$el);
        });
      },
    };
  },
};

export default payInputPlugin;
