import { apiAuthRegister, apiUserResetPwd, apiAuthLogin } from "@/api/api";
import { setLocalStorage } from "@/utils/local-storage";
export default {
  namespaced: true,
  state: {
    showNavigationBar: false,
    address: "",
    blockType: null,
    isLine: false,
    chainId: null,
    payment: null,
    isLogin: false,
    emptyPwd: false,
    invitationCode: null,
    bid: 0,
    pic: "",
    token: "",
    swapTo: 0,
    swapFrom: 0,
  },
  getters: {
    showNavigationBar: (state) => {
      return state.showNavigationBar;
    },
    getLogin: (state) => {
      return state.isLogin;
    },
    getEmptyPwd: (state) => {
      return state.emptyPwd;
    },
    getToken: (state) => {
      return state.token;
    },
    getAddress: (state) => {
      return state.address;
    },
  },
  mutations: {
    clearInfo(state, payload) {
      state.isLogin = false;
      state.emptyPwd = false;
      state.chainId = null;
      state.isLine = false;
      state.token = null;
    },

    addressInfo(state, payload) {
      state.address = payload.address;
      state.blockType = payload.blockType;
      state.chainId = payload.chainId;
      state.isLine = true;
    },

    changeNavigationBar(state) {
      if (state.showNavigationBar) {
        state.showNavigationBar = false;
      } else if (!state.showNavigationBar) {
        state.showNavigationBar = true;
      }
    },

    setLogin(state, isLogin) {
      state.isLogin = isLogin;
    },

    setEmptyPwd(state, emptyPwd) {
      state.emptyPwd = emptyPwd;
    },

    setPayment(state, payment) {
      state.payment = payment;
    },

    setInvitationCode(state, invitationCode) {
      state.invitationCode = invitationCode;
    },

    setBid(state, bid) {
      state.bid = bid;
    },

    setPic(state, pic) {
      state.pic = pic;
    },

    setToken(state, token) {
      state.token = token;
      // setLocalStorage({ token: token });
    },

    setSwapTo(state, swapTo) {
      state.swapTo = swapTo;
    },

    setSwapFrom(state, swapFrom) {
      state.swapFrom = swapFrom;
    },

    setAddress(state, address) {
      state.address = address;
    },
  },
  actions: {
    async authRegister({ commit }, payload) {
      try {
        commit("clearInfo", {});
        let data = await apiAuthRegister({
          address: payload.address,
          blockType: payload.blockType,
          chainId: payload.chainId,
          invitationCode: payload.invitationCode,
        });

        let token = data.token;
        let memberInfo = data.memberInfo;
        let emptyPwd = data.emptyPwd;
        commit("addressInfo", {
          address: payload.address,
          blockType: payload.blockType,
          chainId: payload.chainId,
        });
        commit("setToken", token);
        commit("setEmptyPwd", emptyPwd);
        if (memberInfo) {
          commit("setBid", memberInfo.bid);
          commit("setPic", memberInfo.block.pic);
        }
      } catch (err) {
        commit("addressInfo", {
          address: payload.address,
          blockType: payload.blockType,
          chainId: payload.chainId,
        });
        console.log(err);
      }
    },
    async authLogin({ commit }, payload) {
      try {
        let data = await apiAuthLogin({
          address: payload.address,
          pwd: payload.pwd,
          blockType: payload.blockType,
          chainId: payload.chainId,
        });
        let token = data.token;
        let memberInfo = data.memberInfo;
        let emptyPwd = data.emptyPwd;
        commit("setToken", token);
        commit("setLogin", true);
        commit("setEmptyPwd", emptyPwd);
        if (memberInfo) {
          commit("setBid", memberInfo.bid);
          commit("setPic", memberInfo.block.pic);
        }
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async userResetPwd({ commit }, payload) {
      try {
        let data = await apiUserResetPwd({
          address: payload.address,
          newPwd: payload.newPwd,
        });
        let token = data.token;
        let emptyPwd = data.emptyPwd;
        commit("setToken", token);
        commit("setLogin", true);
        commit("setEmptyPwd", emptyPwd);
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
  },
};
