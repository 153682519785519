export default {
  // 服務語言
  serve: {
    common: {
      verifyFailed: "驗證失敗",
      login: "請先進行登錄",
    },
    approval: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
      lockFailed: "鎖倉失敗",
      lockSuccess: "鎖倉成功",
      notAddress: "系統未配置地址",
      assetsLimit: "您的資産未達到鎖倉最低限制",
    },
    approvalGain: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    auth: {
      querySuccess: "查詢成功",
      notSupportedChainType: "不支持的公鏈類型",
      alreadyRegistered: "已注冊",
      loginFailedUnsupportedType: "不支持的類型",
      accountNotFound: "賬戶不存在",
      incorrectPassword: "密碼錯誤",
    },
    base: {
      notDomin: "未配置域名",
    },
    block: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    borrow: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    borrowOrder: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
      notSupportedBorrow: "當前代幣不支持借入",
      notSupportedCrossChain: "不支持跨鏈",
      orderAmountTooSmall: "訂單金額太小",
      orderAmountTooLarge: "訂單金額太大",
      addSuccess: "新增成功",
      addFailed: "新增失敗",
      orderNotFound: "訂單不存在",
    },
    exchange: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    exchangeOrder: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
      notSupportedExchange: "當前代幣不支持兌換",
      exchangeAmountTooSmall: "兌換金額太小",
      exchangeAmountTooLarge: "兌換金額太大",
      addSuccess: "新增成功",
      addFailed: "新增失敗",
    },
    lixibao: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
      userInfoNotFound: "用戶信息不存在",
      insufficientBalance: "余額不足",
      addSuccess: "新增成功",
    },
    pool: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    poolGoods: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    poolOrder: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
      productNotFound: "當前産品不存在",
      productOutOfStock: "當前産品已下架",
      orderNotFound: "訂單不存在",
      orderNotInProfit: "當前訂單不在收益中",
      userInfoNotFound: "用戶信息不存在",
      addSuccess: "新增成功",
      addFailed: "新增失敗",
      applyWithdrawSuccess: "申請提現成功",
      applyWithdrawFailed: "申請提現失敗",
    },
    token: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    tokenPrice: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    user: {
      accountNotFound: "賬戶不存在",
      passwordError: "密碼錯誤",
      resetPwdSuccess: "密碼重置成功",
      resetPwdFailed: "密碼重置失敗",
      setNotifySuccess: "設置成功",
      setNotifyFailed: "設置失敗",
      loginSuccess: "登錄成功",
      loginFailed: "登錄失敗",
      emptyPwd: "未重置密碼",
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    vip: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    withdraw: {
      querySuccess: "查詢成功",
      queryFailed: "查詢失敗",
    },
    withdrawOrder: {
      querySuccess: "查詢成功",
      insufficientBalance: "余額不足",
      notSupportWithdrawal: "當前代幣不支持提現",
      notSupportBlockchain: "不同區塊鏈的代幣不能提現",
      notSupportBlockchainWithdrawal: "當前區塊鏈不支持提現",
      exceedLimit: "提現數量低於或超過限製",
      submitSuccess: "提現提交成功",
      orderNotFound: "提現訂單不存在",
      withdrawCanceled: "提現已取消",
      userNotFound: "用戶不存在",
    },
  },
  // 前端語言
  menu: {
    home: "首頁",
    login: "用戶登錄",
    risk: "風險信息",
    customer: "客服中心",
    ucenter: "用戶中心",
    swap: "閃兌",
    select: "幣種",
    borrow: "借貸",
    pool: "流動性挖礦",
    level: "等級",
    account: "賬戶明細",
    withdraw: "提現",
    market_fund: "市場基金",
  },
  header: { subtitle: "流動性礦池" },
  common: {
    cancel: "取消",
    confirm: "確認",
    cancelPay: "用戶取消支付",
    enterPassword: "請輸入密碼",
    inactivated: "您的賬號未激活",
    trxInsufficient: "您的TRX不足支付訂單",
    copySuccess: "複制{msg}成功",
    copyFailed: "複制失敗",
  },
  app: {
    lineWallet: "連接錢包中...",
  },
  foot: {
    home: "首頁",
    swap: "閃兌",
    borrow: "借貸",
    pool: "礦池",
    ucenter: "我的",
  },
  language: {
    title: "選擇語言",
  },
  extend: {
    snackbar: {
      close: "關閉",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "確定",
      success: "交易成功",
      fail: "交易失敗",
    },
  },
  home: {
    typewriter1: "壹場數字貨幣的變革,",
    typewriter2: "正在悄然的發生,",
    typewriter3: "而妳准備好了嗎？那麽，現在請加入我們！",
    urlSubtitle: "我們能做什麽？",
    blockSubtitle: "支持幣種",
    swapTitle: "數字貨幣兌換",
    borrowTitle: "抵押借貸",
    poolTitle: "流動性挖礦",
    swapDesc: "提供跨鏈幣種交換，實現多鏈互通願景",
    borrowDesc: "提供抵押借貸幣業務，增強區塊價值屬性",
    poolDesc: "通過您手上的閑置幣加入到平台賺取收益",
    swapBtn: "兌換",
    borrowBtn: "借入",
    poolBtn: "礦池",
  },
  swap: {
    title: "閃兌市場",
    subtitle: "支持多種幣種自由兌換,平台只收取少量手續費",
    placeholder: "輸入數量",
    toAddressPlaceholder: "請輸入{block_name}地址",
    toAddressLabel: "接收地址",
    currentRate: "當前彙率",
    commission: "兌換傭金",
    btn: "兌換",
    records: "兌換記錄",
    goPay: "去支付",
    dialogTitle: "兌換提醒",
    dialogMessage: "是否開始兌換？",
    emptyAddress: "收款地址不能爲空",
    createOrderFail: "創建訂單失敗",
    orderTitle: "兌換訂單",
    orderSubtitle: "核對兌換訂單信息",
    orderNumber: "訂單號",
    status: {
      all: "全部",
      wait: "待支付",
      paySuccess: "支付成功",
      success: "成功",
      fail: "失敗",
    },
    tableHeaders: {
      swap_token: "兌換幣種",
      from_num: "支付數量",
      transfer_num: "接收數量",
      transfer_commission: "交易傭金",
      rate: "彙率",
      create_time: "交易時間",
      status: "狀態",
    },
    orderItems: {
      swap_token: "交換幣種",
      transfer_num: "預計接收",
      transfer_commission: "交易傭金",
      to_address: "接收地址",
      from_num: "應付金額",
    },
  },
  borrow: {
    title: "抵押借幣",
    subtitle: "以幣抵押其他幣種,創造升值。只支持同鏈借貸",
    fromPlaceholder: "請輸入抵押數量",
    fromLabel: "抵押數量",
    toPlaceholder: "請輸入借入數量",
    toLabel: "可借入",
    toAddressPlaceholder: "請輸入{block_name}地址",
    toAddressLabel: "接收地址",
    currentRate: "當前彙率",
    pledge: "抵押率",
    btn: "借入",
    records: "交易記錄",
    goPay: "去支付",
    addBorrow: "增加抵押",
    repayment: "還款",
    none: "無",
    dialogTitle: "借入提醒",
    dialogSubtitle: "是否開始抵押借入？",
    emptyAddress: "收款地址不能爲空",
    notChain: "不支持跨鏈",
    createOrderFail: "創建訂單失敗",
    orderTitle: "借貸信息",
    orderSubtitle: "核對借貸訂單信息",
    repaymentTitle: "還款訂單",
    repaymentSubtitle: "借貸還款訂單信息",
    subOrderDialogTitle: "抵押數量",
    subOrderDialogPlaceholder: "請輸入增加抵押數量",
    subOrderTitle: "增加抵押信息",
    subOrderSubtitle: "增加抵押訂單信息",
    orderNumber: "訂單號",
    status: {
      all: "全部",
      wait: "待支付",
      borrowing: "借貸中",
      over: "已結束",
      disbursement: "放款中",
      repaymentSuccess: "還款成功",
      liquidated: "已清算",
    },
    tableHeaders: {
      borrow_token: "交換幣種",
      from_num: "抵押數量",
      transfer_num: "借入數量",
      transfer_commission: "交易傭金",
      pledge: "抵押率",
      interest: "利息",
      create_time: "交易時間",
      status: "狀態",
      operate: "操作",
    },
    repaymenItems: {
      swap_token: "交換幣種",
      transfer_num: "借入本金",
      interest: "利息",
      repayment: "應還數量",
      from_num: "返還抵押",
    },
    subOrderItems: {
      from_num: "應付金額",
      token: "抵押幣種",
    },
    orderItems: {
      token_symbol: "交換幣種",
      transfer_num: "預計接收",
      transfer_commission: "交易傭金",
      pledge: "抵押率",
      to_address: "接收地址",
      from_num: "應付金額",
    },
  },
  pool: {
    header: "流動性挖礦",
    headerSubtitle: "參與全新鎖倉挖礦 獲取更多雙幣收益",
    totalEarnings: "總收益",
    todayEarnings: "今日收益",
    minePool: "礦池",
    mySupply: "我的收益",
    myTeam: "團隊",
    poolTime: "區塊時間",
    product: {
      lockBtn: "鎖倉增益",
      myLockPools: "我的鎖倉",
      joined: "已加入",
      earned: "已收益",
      profitTime: "收益時間",
      currentChain: "當前公鏈",
      otherChains: "其他公鏈",
      supplyInformationUnavailable: "當前公鏈暫無供應信息",
      belongingChain: "所屬公鏈",
      supplyToken: "供應代幣",
      marketPrice: "市場價格",
      settlementMethod: "結算方式",
      day: "天",
      periodAPY: "DPR",
      assets: "您的資産",
      dailyIncome: "預計收益/日",
      join: "加入",
      joined: "已加入",
      supply: "供應",
      orderNumber: "訂單號",
      supplyProduct: "供應産品",
      supplyCurrency: "供應幣種",
      supplyAmount: "供應數量",
      interestRate: "DPR",
      estimatedEarnings: "預計收益",
      paymentAddress: "收款地址",
      supplyOrder: "供應訂單",
      checkOrderInfo: "核對供應訂單信息",
      pledged: {
        ChooseInfo: "選擇鎖倉周期",
        lockupPeriod: "鎖倉周期",
        lockNum: "鎖倉數量",
        lockTime: "鎖倉時間",
        lockLimit: "最低限製",
        originalDpr: "DPR(原始)",
        lockDpr: "DPR(鎖倉)",
        allDpr: "DPR(總)",
        lockCaption: "鎖倉期間您不能操作該資産，但收益增加。",
        confirmInfo: "確認信息",
        confirmMessage: "是否確認對該資産鎖倉({day})天，增加收益？",
        day: "天",
        ok: "確定",
      },
    },
    record: {
      orderNumber: "訂單號",
      supplyAssets: "供應資産",
      supplyAmount: "供應數量",
      interestRate: "DPR",
      transactionTime: "交易時間",
      status: "狀態",
      all: "全部",
      unpaid: "未支付",
      income: "收益中",
      completed: "已結束",
      pendingPayment: "待支付",
      goToPay: "去支付",
      earning: "收益中",
      withdraw: "提現",
      completed: "已結束",
      successfulSubmission: "提交成功，請等待審核...",
      withdrawalOrderApplicationFailed: "申請提現失敗",
      day: "天",
      incomeAsset: "收益資産",
      incomeNum: "收益數量",
      uNum: "實際收益(U)",
      uRate: "彙率(U)",
      incomeTime: "收益時間",
    },
    team: {
      inviteCode: "邀請碼",
      teamMembers: "團隊人數",
      inviteInfo: "邀請信息",
      firstLevelInvite: "壹級邀請",
      secondLevelInvite: "二級邀請",
      thirdLevelInvite: "三級邀請",
      copySuccess: "複制成功",
      copyError: "該浏覽器不支持自動複制",
    },
  },
  ucenter: {
    risk: {
      riskInfo: "您的賬戶風險信息",
      remove: "解除",
      checkInfo:
        "系統檢測到您的({token})資產賬戶有異常風險，為保障資金安全，請立即處理。",
      removeRisk: "解除風險",
      riskInformation: "風險信息",
      removeSuccess: "解除成功",
    },
    withdraw: {
      withdraw: "提現",
      withdrawRecords: "提現記錄",
      withdrawNum: "提現數量",
      withdrawCurrency: "提現幣種",
      withdrawLimit: "提現限製",
      currentRate: "當前彙率",
      fee: "手續費",
      estimatedArrival: "預計到賬",
      cancel: "取消",
      pendingReview: "審核中",
      withdrawalOrderInProgress: "出款中",
      withdrawalOrderSuccess: "提現成功",
      success: "成功",
      fail: "失敗",
      rejected: "駁回",
      withdrawalOrderEnded: "已結束",
      selectAll: "全部",
      withdrawReminder: "提現提醒",
      withdrawConfirmation: "是否提現{0}{1}？",
      withdrawSuccessMessage: "申請提現成功",
      withdrawCancelMessage: "取消成功",
      userCenter: "用戶中心",
      accountWithdrawal: "賬戶提現",
      enterWithdrawalAmount: "請輸入提現數量",
      withdrawMin: "單次最小提現數量為:{0} USDT",
      withdrawMax: "單次最大提現數量為:{0} USDT",
      withdrawButton: "提現",
      withdrawRecordsTitle: "提現記錄",
      orderNumber: "訂單號",
      withdrawalAsset: "提現資産",
      withdrawalAmountLabel: "提現金額",
      withdrawalNumLabel: "提現數量",
      withdrawalShowXuLabel: "手續費",
      applicationTime: "申請時間",
      status: "狀態",
      withdrawalOrderStatusPendingReview: "審核中",
      withdrawalOrderStatusProcessing: "處理中",
      withdrawalOrderStatusRejected: "駁回",
      withdrawalOrderStatusInProgress: "出款中",
      withdrawalOrderStatusSuccess: "提現成功",
      withdrawalOrderStatusFaild: "提现失败",
      withdrawalOrderStatusCancel: "已取消",
      cancelWithdrawal: "取消",
    },
    marketFund: {
      marketFund: "市場基金",
      stableMarketFund: "穩定基金市場，提升平台流動資金",
      yieldRate: "收益率",
      todayEarnings: "今日收益",
      yourLevel: "您的等級",
      upgradeLevel: "提升等級",
      totalEarnings: "基金總收益",
      accountBalance: "賬戶余額",
      ownedFund: "擁有基金",
      myFunds: "我的基金",
      orderNumber: "訂單號",
      amount: "金額",
      time: "買入時間",
      status: "狀態",
      all: "全部",
      transferIn: "轉入",
      earnings: "收益",
      transferOut: "轉出",
      transferInNum: "買入數量",
      transferOutNum: "轉出數量",
      inputNum: "請輸入數量",
      insufficientBalance: "賬戶余額不足",
      buySuccess: "買入成功",
      insufficientFundBalance: "基金余額不足",
      transferOutSuccess: "轉出成功",
      buyReminder: "買入提醒",
      buyFundMessage: "是否開始買入基金數量: {num} USDT？",
      transferReminder: "轉出提醒",
      transferFundMessage:
        "是否轉出基金數量: {num} USDT，轉出後基金收益將減少？",
      fundTime: "基金時間",
      earningsTime: "收益時間",
    },
    level: {
      levelExplanation: "等級說明",
      marketFundLevelEquity: "市場基金等級權益",
      contribution: "貢獻度",
      contributionValue: "您的貢獻度",
      levelIncomeSystem: "等級收益制度",
      level: "等級",
      contributionU: "貢獻度(U)",
      incomeRate: "收益率",
      howToImproveLevel: "如何提升等級？",
      liquidityPool: "流動性礦池",
      improveContribution: "供應任何産品都均可提升您的貢獻度來提升等級。",
      levelImpact:
        "等級影響您在市場基金的收益率，爲了維護平台客戶的公平權益，市場基金的收益按照用戶對平台的供應數量轉爲USDT價值爲准。",
      yourContribution: "您的貢獻度",
      levelIncomeSystem: "等級收益制度",
      howToImproveLevelDesc:
        "進入流動性礦池，供應任何産品都均可提升您的貢獻度來提升等級。",
    },
    customer: {
      userCenter: "用戶中心",
      customerCenter: "客服中心",
    },
    account: {
      orderNumber: "訂單號",
      transactionAmount: "交易金額",
      transactionTime: "交易時間",
      transactionType: "交易類型",
      transactionRebate: "交易返傭",
      userWithdrawal: "用戶提現",
      withdrawalOrderRejected: "提現駁回",
      supplyPrincipal: "供應本金",
      withdrawalOrderRefused: "提現拒絕",
      userCenter: "用戶中心",
      accountDetails: "賬戶明細",
      filterSettings: "篩選設置",
      all: "全部",
      income: "收入",
      expenditure: "支出",
      poolIncome: "礦池收益",
      lockIncome: "鎖倉收益",
      fundTransferOut: "基金轉出",
      fundTransferIn: "基金轉入",
      userCancel: "用戶取消",
    },
    index: {
      userBalance: "用戶余額",
      marketFund: "市場基金",
      withdraw: "提現",
      pledgedAssets: "質押資産",
      onChainAssets: "鏈上資産",
      informationSettings: "信息/設置",
      myLedger: "我的賬本",
      customerCenter: "客服中心",
      passwordSettings: "密碼設置",
      languageSettings: "語言設置",
    },
    userLogin: {
      inputPassword: "請輸入安全密碼",
      setPassword: "請設置您的安全密碼",
      forgetPassword: "重置？",
      loginSuccess: "登錄成功",
      setPassSuccess: "設置安全密碼成功",
      resetSuccessful: "重置密碼成功",
      signFailed: "簽名錯誤",
      resetReminder: "重置提醒",
      resetInstructions:
        "即將把安全密碼重置爲:123456, 重置完成請到會員中心修改新密碼, 請確定繼續操作？",
    },
  },
};
