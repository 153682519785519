export default {
  // 服务语言
  serve: {
    common: {
      verifyFailed: "検証失敗",
      login: "先にログインしてください",
    },
    approval: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
      lockFailed: "ロックに失敗しました",
      lockSuccess: "ロックに成功しました",
      notAddress: "システムにアドレスが設定されていません",
      assetsLimit: "お持ちの資産は最小ステーキング制限に達していません",
    },
    approvalGain: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    auth: {
      querySuccess: "クエリ成功",
      notSupportedChainType: "サポートされていない公開チェーンタイプ",
      alreadyRegistered: "既に登録済み",
      loginFailedUnsupportedType: "サポートされていないタイプ",
      accountNotFound: "アカウントが存在しません",
      incorrectPassword: "パスワードが間違っています",
    },
    base: {
      notDomin: "ドメインが設定されていません",
    },
    block: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    borrow: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    borrowOrder: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
      notSupportedBorrow: "現在のトークンは借り入れをサポートしていません",
      notSupportedCrossChain: "クロスチェーンはサポートされていません",
      orderAmountTooSmall: "注文金額が小さすぎます",
      orderAmountTooLarge: "注文金額が大きすぎます",
      addSuccess: "追加成功",
      addFailed: "追加失敗",
      orderNotFound: "注文が存在しません",
    },
    exchange: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    exchangeOrder: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
      notSupportedExchange: "現在のトークンは交換をサポートしていません",
      exchangeAmountTooSmall: "交換金額が小さすぎます",
      exchangeAmountTooLarge: "交換金額が大きすぎます",
      addSuccess: "追加成功",
      addFailed: "追加失敗",
    },
    lixibao: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
      userInfoNotFound: "ユーザー情報が存在しません",
      insufficientBalance: "残高が不足しています",
      addSuccess: "追加成功",
    },
    pool: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    poolGoods: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    poolOrder: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
      productNotFound: "現在の製品は存在しません",
      productOutOfStock: "現在の製品は売り切れです",
      orderNotFound: "注文が存在しません",
      orderNotInProfit: "現在の注文は収益中ではありません",
      userInfoNotFound: "ユーザー情報が存在しません",
      addSuccess: "追加成功",
      addFailed: "追加失敗",
      applyWithdrawSuccess: "引き出し申請成功",
      applyWithdrawFailed: "引き出し申請失敗",
    },
    token: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    tokenPrice: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    user: {
      accountNotFound: "アカウントが存在しません",
      passwordError: "パスワードエラー",
      resetPwdSuccess: "パスワードリセット成功",
      resetPwdFailed: "パスワードリセット失敗",
      setNotifySuccess: "設定成功",
      setNotifyFailed: "設定失敗",
      loginSuccess: "ログイン成功",
      loginFailed: "ログイン失敗",
      emptyPwd: "パスワードがリセットされていません",
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    vip: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    withdraw: {
      querySuccess: "クエリ成功",
      queryFailed: "クエリ失敗",
    },
    withdrawOrder: {
      querySuccess: "クエリ成功",
      insufficientBalance: "残高が不足しています",
      notSupportWithdrawal: "現在のトークンは引き出しをサポートしていません",
      notSupportBlockchain:
        "異なるブロックチェーンのトークンは引き出しできません",
      exceedLimit: "出金額は制限を下回るか制限を超えています",
      notSupportBlockchainWithdrawal:
        "現在のブロックチェーンは引き出しをサポートしていません",
      submitSuccess: "引き出し申請成功",
      orderNotFound: "引き出し注文が存在しません",
      withdrawCanceled: "引き出しがキャンセルされました",
      userNotFound: "ユーザーが存在しません",
    },
  },
  // 前端语言
  menu: {
    home: "ホーム",
    login: "ログイン",
    risk: "リスク情報",
    customer: "カスタマーサービスセンター",
    ucenter: "ユーザーセンター",
    swap: "クイックスワップ",
    select: "通貨選択",
    borrow: "借り入れ",
    pool: "流動性マイニング",
    level: "レベル",
    account: "アカウント詳細",
    withdraw: "引き出し",
    market_fund: "マーケットファンド",
  },
  header: { subtitle: "流動性マイニングプール" },
  common: {
    cancel: "キャンセル",
    confirm: "確認",
    cancelPay: "ユーザーによる支払いキャンセル",
    enterPassword: "パスワードを入力してください",
    inactivated: "アカウントが有効化されていません",
    trxInsufficient: "ご注文に支払うのに十分なTRXがありません",
    copySuccess: "{msg}のコピーが成功しました",
    copyFailed: "コピーに失敗しました",
  },
  app: {
    lineWallet: "ウォレットに接続中...",
  },
  foot: {
    home: "ホーム",
    swap: "クイックスワップ",
    borrow: "借り入れ",
    pool: "マイニングプール",
    ucenter: "マイページ",
  },
  language: {
    title: "言語選択",
  },
  extend: {
    snackbar: {
      close: "閉じる",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "もちろん",
      success: "成功したトランザクション",
      fail: "処理に失敗しました",
    },
  },
  home: {
    typewriter1: "暗号通貨の革命が",
    typewriter2: "静かに進行中です",
    typewriter3:
      "そして、あなたは準備ができていますか？それでは、私たちに参加してください！",
    urlSubtitle: "私たちは何ができますか？",
    blockSubtitle: "サポートされている通貨",
    swapTitle: "デジタル通貨の交換",
    borrowTitle: "抵当借入",
    poolTitle: "流動性マイニング",
    swapDesc:
      "クロスチェーン通貨交換を提供し、多様なブロックチェーン間の相互運用を実現します",
    borrowDesc:
      "抵当借入の通貨ビジネスを提供し、ブロックチェーンの価値属性を強化します",
    poolDesc: "手元の不活性な通貨をプラットフォームに供給して収益を得る",
    swapBtn: "交換",
    borrowBtn: "借入",
    poolBtn: "マイニングプール",
  },
  swap: {
    title: "スワップ市場",
    subtitle:
      "多種類の通貨を自由に交換できます。プラットフォームはわずかな手数料のみを取得します。",
    placeholder: "数量を入力",
    toAddressPlaceholder: "{block_name}アドレスを入力してください",
    toAddressLabel: "受信アドレス",
    currentRate: "現在のレート",
    commission: "交換手数料",
    btn: "交換",
    records: "交換記録",
    goPay: "支払いに進む",
    dialogTitle: "交換確認",
    dialogMessage: "交換を開始しますか？",
    emptyAddress: "受信アドレスが空白です",
    createOrderFail: "注文の作成に失敗しました",
    orderTitle: "交換注文",
    orderSubtitle: "交換注文情報を確認してください",
    orderNumber: "注文番号",
    status: {
      all: "すべて",
      wait: "支払い待ち",
      paySuccess: "支払い成功",
      success: "成功",
      fail: "失敗",
    },
    tableHeaders: {
      swap_token: "交換通貨",
      from_num: "支払い数量",
      transfer_num: "受信数量",
      transfer_commission: "取引手数料",
      rate: "レート",
      create_time: "取引時間",
      status: "状態",
    },
    orderItems: {
      swap_token: "交換通貨",
      transfer_num: "受信予定",
      transfer_commission: "取引手数料",
      to_address: "受信アドレス",
      from_num: "支払い金額",
    },
  },
  borrow: {
    title: "担保借入",
    subtitle:
      "抵押資産を使って他の通貨を借り入れ、価値を創造します。同一チェーン間の借り入れにのみ対応しています",
    fromPlaceholder: "抵押数量を入力してください",
    fromLabel: "抵押数量",
    toPlaceholder: "借入数量を入力してください",
    toLabel: "借入可能数量",
    toAddressPlaceholder: "{block_name}アドレスを入力してください",
    toAddressLabel: "受信アドレス",
    currentRate: "現在のレート",
    pledge: "担保率",
    btn: "借入",
    records: "取引記録",
    goPay: "支払いに進む",
    addBorrow: "担保を増やす",
    repayment: "返済",
    none: "なし",
    dialogTitle: "借入確認",
    dialogSubtitle: "担保借入を開始しますか？",
    emptyAddress: "受信アドレスが空白です",
    notChain: "クロスチェーンはサポートされていません",
    createOrderFail: "注文の作成に失敗しました",
    orderTitle: "借入情報",
    orderSubtitle: "担保借入注文情報を確認してください",
    repaymentTitle: "返済注文",
    repaymentSubtitle: "借入返済注文情報を確認してください",
    subOrderDialogTitle: "担保数量",
    subOrderDialogPlaceholder: "増加する担保数量を入力してください",
    subOrderTitle: "担保追加情報",
    subOrderSubtitle: "担保追加注文情報を確認してください",
    orderNumber: "注文番号",
    status: {
      all: "すべて",
      wait: "支払い待ち",
      borrowing: "借入中",
      over: "完了",
      disbursement: "出金中",
      repaymentSuccess: "返済成功",
      liquidated: "清算済み",
    },
    tableHeaders: {
      borrow_token: "交換通貨",
      from_num: "担保数量",
      transfer_num: "借入数量",
      transfer_commission: "取引手数料",
      pledge: "担保率",
      interest: "利息",
      create_time: "取引時間",
      status: "状態",
      operate: "操作",
    },
    repaymenItems: {
      swap_token: "交換通貨",
      transfer_num: "借入元本",
      interest: "利息",
      repayment: "返済数量",
      from_num: "返済担保",
    },
    subOrderItems: {
      from_num: "支払い金額",
      token: "担保通貨",
    },
    orderItems: {
      token_symbol: "交換通貨",
      transfer_num: "受信予定",
      transfer_commission: "取引手数料",
      pledge: "担保率",
      to_address: "受信アドレス",
      from_num: "支払い金額",
    },
  },

  pool: {
    header: "流動性マイニング",
    headerSubtitle:
      "新しいステーキングマイニングに参加して、デュアル通貨の収益を増やしましょう",
    totalEarnings: "合計収益",
    todayEarnings: "今日の収益",
    minePool: "マイニングプール",
    mySupply: "私の収益",
    myTeam: "チーム",
    poolTime: "ブロック時間",
    product: {
      lockBtn: "ステーキングの利益",
      myLockPools: "私のステーキング",
      joined: "参加済み",
      earned: "収益",
      profitTime: "収益の時間",
      currentChain: "現在のブロックチェーン",
      otherChains: "他のブロックチェーン",
      supplyInformationUnavailable:
        "現在のブロックチェーンの供給情報は利用できません",
      belongingChain: "所属するチェーン",
      supplyToken: "供給トークン",
      marketPrice: "市場価格",
      settlementMethod: "決済方法",
      day: "日",
      periodAPY: "APY",
      assets: "あなたの資産",
      dailyIncome: "予想される日次収益",
      join: "参加する",
      joined: "参加済み",
      supply: "供給",
      orderNumber: "注文番号",
      supplyProduct: "供給商品",
      supplyCurrency: "供給通貨",
      supplyAmount: "供給数量",
      interestRate: "APY",
      estimatedEarnings: "予想収益",
      paymentAddress: "支払いアドレス",
      supplyOrder: "供給注文",
      checkOrderInfo: "供給注文情報の確認",
      pledged: {
        ChooseInfo: "ロックアップ期間を選択",
        lockupPeriod: "ロックアップ期間",
        lockNum: "ロック数量",
        lockTime: "ロック期間",
        lockLimit: "最低制限",
        originalDpr: "元のAPY",
        lockDpr: "ロックAPY",
        allDpr: "合計APY",
        lockCaption:
          "ロックアップ期間中はこの資産の操作ができませんが、収益が増加します。",
        confirmInfo: "情報を確認",
        confirmMessage: "{day}日間資産をロックして収益を増やしますか？",
        day: "日",
        ok: "確認",
      },
    },

    record: {
      orderNumber: "注文番号",
      supplyAssets: "供給資産",
      supplyAmount: "供給数量",
      interestRate: "DPR",
      transactionTime: "取引時間",
      status: "ステータス",
      all: "すべて",
      unpaid: "未払い",
      income: "収益中",
      completed: "完了",
      pendingPayment: "支払い待ち",
      goToPay: "支払いへ",
      earning: "収益中",
      withdraw: "引き出し",
      completed: "完了",
      successfulSubmission: "送信成功、審査をお待ちください...",
      withdrawalOrderApplicationFailed: "引き出しオーダーの申請に失敗しました",
      day: "日",
      incomeAsset: "収益資産",
      incomeNum: "収益数量",
      uNum: "実際の収入(U)",
      uRate: "為替レート(U)",
      incomeTime: "収益時間",
    },
    team: {
      inviteCode: "招待コード",
      teamMembers: "チームメンバー数",
      inviteInfo: "招待情報",
      firstLevelInvite: "1次招待",
      secondLevelInvite: "2次招待",
      thirdLevelInvite: "3次招待",
      copySuccess: "コピー成功",
      copyError: "このブラウザは自動コピーに対応していません",
    },
  },
  ucenter: {
    risk: {
      riskInfo: "アカウントリスク情報",
      remove: "解除",
      checkInfo:
        "システムは({token})アセットアカウントに異常なリスクを検出しました。資金を保護するため、すぐに対処してください。",
      removeRisk: "リスクの解除",
      riskInformation: "リスク情報",
      removeSuccess: "解除成功",
    },
    withdraw: {
      withdraw: "出金",
      withdrawRecords: "出金記録",
      withdrawNum: "出金数量",
      withdrawCurrency: "出金通貨",
      currentRate: "現在の為替レート",
      withdrawLimit: "出金限度額",
      fee: "手数料",
      estimatedArrival: "予想到着",
      cancel: "キャンセル",
      pendingReview: "審査中",
      withdrawalOrderInProgress: "出金処理中",
      withdrawalOrderSuccess: "出金成功",
      success: "成功",
      fail: "失敗",
      rejected: "却下",
      withdrawalOrderEnded: "終了",
      selectAll: "すべて選択",
      withdrawReminder: "出金リマインダー",
      withdrawConfirmation: "{0}{1}を出金しますか？",
      withdrawSuccessMessage: "出金申請が成功しました",
      withdrawCancelMessage: "キャンセルが成功しました",
      userCenter: "ユーザーセンター",
      accountWithdrawal: "口座出金",
      enterWithdrawalAmount: "出金数量を入力してください",
      withdrawMin: "一回の取引ごとの最小引き出し金額は{0} USDTです。",
      withdrawMax: "一回の取引ごとの最大引き出し金額は{0} USDTです。",
      withdrawButton: "出金",
      withdrawRecordsTitle: "出金記録",
      orderNumber: "注文番号",
      withdrawalAsset: "引き出し資産",
      withdrawalAmountLabel: "引き出し金額",
      withdrawalNumLabel: "引き出し数量",
      withdrawalShowXuLabel: "手数料",
      applicationTime: "申請時間",
      status: "ステータス",
      withdrawalOrderStatusPendingReview: "審査中",
      withdrawalOrderStatusProcessing: "処理中",
      withdrawalOrderStatusRejected: "拒否",
      withdrawalOrderStatusInProgress: "進行中",
      withdrawalOrderStatusSuccess: "出金成功",
      withdrawalOrderStatusFaild: "出金失敗",
      withdrawalOrderStatusCancel: "キャンセル済み",
      cancelWithdrawal: "キャンセル",
    },
    marketFund: {
      marketFund: "マーケットファンド",
      stableMarketFund:
        "安定ファンド市場、プラットフォームの流動資金を向上させます",
      yieldRate: "利回り",
      todayEarnings: "今日の収益",
      yourLevel: "あなたのレベル",
      upgradeLevel: "レベルアップ",
      totalEarnings: "ファンドの総収益",
      accountBalance: "アカウント残高",
      ownedFund: "保有ファンド",
      myFunds: "私のファンド",
      orderNumber: "注文番号",
      amount: "金額",
      time: "買入時間",
      status: "ステータス",
      all: "すべて",
      transferIn: "転入",
      earnings: "収益",
      transferOut: "転出",
      transferInNum: "買入数量",
      transferOutNum: "転出数量",
      inputNum: "数量を入力してください",
      insufficientBalance: "アカウント残高が不足しています",
      buySuccess: "買入が成功しました",
      insufficientFundBalance: "ファンド残高が不足しています",
      transferOutSuccess: "転出が成功しました",
      buyReminder: "買入リマインダー",
      buyFundMessage: "ファンド数量： {num} USDTを買入しますか？",
      transferReminder: "転出リマインダー",
      transferFundMessage:
        "{num} USDTのファンドを転出しますか？ 転出後、ファンド収益が減少します。",
      fundTime: "ファンド時間",
      earningsTime: "収益時間",
    },
    level: {
      levelExplanation: "レベルの説明",
      marketFundLevelEquity: "マーケットファンドレベルの権益",
      contribution: "貢献度",
      contributionValue: "あなたの貢献度",
      levelIncomeSystem: "レベル収益制度",
      level: "レベル",
      contributionU: "貢献度(U)",
      incomeRate: "利回り",
      howToImproveLevel: "レベルアップの方法",
      liquidityPool: "流動性マイニングプール",
      improveContribution:
        "任意の製品を供給して、あなたの貢献度をアップグレードできます。",
      levelImpact:
        "レベルはマーケットファンドの収益率に影響を与えます。プラットフォームの公平な権益を維持するため、マーケットファンドの収益はユーザーがプラットフォームに供給した数量に基づいてUSDT価値に換算されます。",
      yourContribution: "あなたの貢献度",
      levelIncomeSystem: "レベル収益制度",
      howToImproveLevelDesc:
        "流動性マイニングプールに入ることで、任意の製品を供給して、あなたの貢献度をアップグレードできます。",
    },
    customer: {
      userCenter: "ユーザーセンター",
      customerCenter: "カスタマーセンター",
    },
    account: {
      orderNumber: "注文番号",
      transactionAmount: "取引金額",
      transactionTime: "取引時間",
      transactionType: "取引タイプ",
      transactionRebate: "取引リベート",
      userWithdrawal: "ユーザー出金",
      withdrawalOrderRejected: "出金却下",
      supplyPrincipal: "供給元本",
      withdrawalOrderRefused: "出金拒否",
      userCenter: "ユーザーセンター",
      accountDetails: "アカウント明細",
      filterSettings: "フィルター設定",
      all: "すべて",
      income: "収入",
      expenditure: "支出",
      poolIncome: "マイニングプール収益",
      lockIncome: "ロックアップ収入",
      fundTransferOut: "ファンド転出",
      fundTransferIn: "ファンド転入",
      userCancel: "ユーザーキャンセル",
    },
    index: {
      userBalance: "ユーザー残高",
      marketFund: "マーケットファンド",
      withdraw: "出金",
      pledgedAssets: "ロックアップ",
      onChainAssets: "オンチェーン資産",
      informationSettings: "情報/設定",
      myLedger: "私の帳簿",
      customerCenter: "カスタマーセンター",
      passwordSettings: "パスワード設定",
      languageSettings: "言語設定",
    },
    userLogin: {
      inputPassword: "セキュリティパスワードを入力してください",
      setPassword: "セキュリティパスワードを設定してください",
      forgetPassword: "リセット?",
      loginSuccess: "ログイン成功",
      setPassSuccess: "セキュリティパスワードの設定に成功しました",
      resetSuccessful: "パスワードのリセットに成功しました",
      signFailed: "署名エラー",
      resetReminder: "リセットのリマインダー",
      resetInstructions:
        "セキュリティパスワードが123456にリセットされます。リセットが完了したら、メンバーセンターで新しいパスワードに変更してください。続行しますか？",
    },
  },
};
