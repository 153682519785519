import Vue from "vue";
import Vuex from "vuex";
import globals from "./modules/globals.js";
import { block } from "./modules/block";
import { token } from "./modules/token";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV != "production",
  state: {

  },
  mutations: {
  },
  actions: {},
  modules: {
    globals,
    block,
    token,
  },
});

// 数据保存在index.js文件中，可使用this.$store.state来获取自定义数据

// Getters
// 相当于vue中的computed计算属性，getter 的返回值会根据它的依赖被缓存起来，且只有当它的依赖值发生了改变才会被重新计算。Getters 可以用于监听、state中的值的变化，返回计算后的结果
// this.$store.getters.getStoreCount
// //页面中这样写可直接调用getters中的方法

// Mutations
// 用来修改store中的值
// //在页面的方法中调用mutation的方法
// this.$store.commit("add");

// Action
// 虽然上述方法可以修改页面的值，但是官方并不建议这样做，我们可以使用action调用mutation改变状态的值
//this.$store.dispatch("add");

// dispatch:含有异步操作，数据提交至 actions ，可用于向后台提交数据
// 写法示例: this.$store.dispatch('add', true);
// commit:同步操作，数据提交至 mutations ，可用于登录成功后读取用户信息写到缓存里
// 写法示例: this.$store.commit('add', 1);
// 两者都可以以载荷形式或者对象风格的方式进行提交
export default store;
