import Vue from "vue";
import VueI18n from "vue-i18n";
import zhHans from "@/locale/zh-Hans";
import zhHant from "@/locale/zh-Hant";
import en from "@/locale/en";
import ja from "@/locale/ja";
import ko from "@/locale/ko";
import es from "./es";
import { getLocalStorage } from "@/utils/local-storage";

Vue.use(VueI18n);
const { language } = getLocalStorage("language");

let i18n = new VueI18n({
  locale: language || "en",
  messages: {
    en,
    es,
    ja,
    ko,
    zhHans,
    zhHant,
  },
});

i18n.formatTime = (time, utc = false) => {
  if (typeof time === "string") time = new Date(time.replace(/-/g, "/"));
  const timeLang = {
    en: "en-US",
    es: "es-ES",
    ja: "ja-JP",
    ko: "ko-KR",
    zhHans: "zh-CN",
    zhHant: "zh-CN",
  };
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  if (utc) {
    options.timeZone = "UTC";
  }
  return new Intl.DateTimeFormat(timeLang[i18n.locale], options).format(time);
};
export default i18n;
