const { TrcApi } = require("./trc");
const { ErcApi } = require("./erc");

class Payment {
  constructor(address, blockType, chainId) {
    this.address = address;
    this.blockType = blockType;
    this.chainId = chainId;
  }

  // async switch(chainId) {
  //   if (chainId != this.chainId) {
  //     try {
  //       let chainid = '0x' + chainId.toString(16);
  //       await window.ethereum.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: chainid }]
  //       });
  //       return false;
  //     } catch (switchError) {
  //       console.log(switchError);
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  get_address() {
    return this.address;
  }

  get_blockType() {
    return this.blockType;
  }

  get_chainId() {
    return this.chainId;
  }

  async sendNative(to_address, amount) {
    switch (this.blockType) {
      case 0:
        return await TrcApi.sendNative(to_address, amount, this.address);
      case 1:
        return await ErcApi.sendNative(to_address, amount, this.address);
      default:
        break;
    }
  }

  async approval(contract, spender_address, amount) {
    switch (this.blockType) {
      case 0:
        return await TrcApi.approval(
          this.address,
          contract,
          spender_address,
          amount
        );
      case 1:
        return await ErcApi.approval(
          this.address,
          contract,
          spender_address,
          amount
        );
      default:
        break;
    }
  }

  async transfer(contract, spender_address, amount, precision) {
    switch (this.blockType) {
      case 0:
        return await TrcApi.transfer(
          this.address,
          contract,
          spender_address,
          amount,
          precision
        );
      case 1:
        return await ErcApi.transfer(
          this.address,
          contract,
          spender_address,
          amount,
          precision
        );
      default:
        break;
    }
  }

  async balanceOf(contract, address, precision) {
    switch (this.blockType) {
      case 0:
        return await TrcApi.balanceOf(contract, address, precision);
      case 1:
        return await ErcApi.balanceOf(contract, address, precision);
      default:
        break;
    }
  }
  async getBalance(precision) {
    switch (this.blockType) {
      case 0:
        return await TrcApi.getBalance(precision);
      case 1:
        return await ErcApi.getBalance(precision);
      default:
        break;
    }
  }

  async resetPassword() {
    switch (this.blockType) {
      case 0:
        return await TrcApi.resetPassword();
      case 1:
        return await ErcApi.resetPassword();
      default:
        break;
    }
  }
}

export default Payment;
