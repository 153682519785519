import { apiTokenRetrieve } from '@/api/api';
export const token = {
  namespaced: true,
  state: {
    tokenMap: null,
    tokens: null
  },
  mutations: {
    setTokenMap(state, tokenMap) {
      state.tokenMap = tokenMap;
    },
    setTokens(state, tokens) {
      state.tokens = tokens;
    }
  },
  actions: {
    // 从后台获取动态路由
    async getToken({ commit, state }) {
      if (state.tokenMap) {
        return state.tokenMap;
      } else {
        const res = await apiTokenRetrieve();
        const tokenMap = {};
        res &&
          res.map(item => {
            tokenMap[item.id] = item;
          });

        commit('setTokenMap', tokenMap);
        commit('setTokens', res);
        return state.tokenMap;
      }
    }
  },
  getters: {
    getTokenMap(state) {
      return state.tokenMap;
    },
    getTokens(state) {
      return state.tokens;
    }
  }
};
