export default {
  // 服务语言

  serve: {
    common: {
      verifyFailed: "Fallo en la verificación",
      login: "Por favor, inicie sesión primero",
    },
    approval: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
      lockFailed: "Fallo al bloquear",
      lockSuccess: "Bloqueo exitoso",
      notAddress: "Dirección no configurada en el sistema",
      assetsLimit: "Tus activos no han alcanzado el límite mínimo de staking",
    },
    approvalGain: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    auth: {
      querySuccess: "Consulta exitosa",
      notSupportedChainType: "Tipo de cadena pública no soportada",
      alreadyRegistered: "Ya registrado",
      loginFailedUnsupportedType: "Tipo no soportado",
      accountNotFound: "Cuenta no encontrada",
      incorrectPassword: "Contraseña incorrecta",
    },
    base: {
      notDomin: "Nombre de dominio no configurado",
    },
    block: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    borrow: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    borrowOrder: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
      notSupportedBorrow: "Préstamo no soportado para esta moneda",
      notSupportedCrossChain: "Intercambio entre cadenas no soportado",
      orderAmountTooSmall: "Monto del pedido demasiado pequeño",
      orderAmountTooLarge: "Monto del pedido demasiado grande",
      addSuccess: "Añadido exitosamente",
      addFailed: "Error al agregar",
      orderNotFound: "Pedido no encontrado",
    },
    exchange: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    exchangeOrder: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
      notSupportedExchange: "Intercambio no soportado para esta moneda",
      exchangeAmountTooSmall: "Monto del intercambio demasiado pequeño",
      exchangeAmountTooLarge: "Monto del intercambio demasiado grande",
      addSuccess: "Añadido exitosamente",
      addFailed: "Error al agregar",
    },
    lixibao: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
      userInfoNotFound: "Información de usuario no encontrada",
      insufficientBalance: "Saldo insuficiente",
      addSuccess: "Añadido exitosamente",
    },
    pool: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    poolGoods: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    poolOrder: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
      productNotFound: "Producto no encontrado",
      productOutOfStock: "Producto agotado",
      orderNotFound: "Pedido no encontrado",
      orderNotInProfit: "Pedido actual no está en ganancias",
      userInfoNotFound: "Información de usuario no encontrada",
      addSuccess: "Añadido exitosamente",
      addFailed: "Error al agregar",
      applyWithdrawSuccess: "Solicitud de retiro exitosa",
      applyWithdrawFailed: "Error al solicitar retiro",
    },
    token: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    tokenPrice: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    user: {
      accountNotFound: "Cuenta no encontrada",
      passwordError: "Contraseña incorrecta",
      resetPwdSuccess: "Restablecimiento de contraseña exitoso",
      resetPwdFailed: "Error al restablecer contraseña",
      setNotifySuccess: "Configuración exitosa",
      setNotifyFailed: "Error en la configuración",
      loginSuccess: "Inicio de sesión exitoso",
      loginFailed: "Inicio de sesión fallido",
      emptyPwd: "Contraseña no restablecida",
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    vip: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    withdraw: {
      querySuccess: "Consulta exitosa",
      queryFailed: "Consulta fallida",
    },
    withdrawOrder: {
      querySuccess: "Consulta exitosa",
      insufficientBalance: "Saldo insuficiente",
      notSupportWithdrawal: "Retiro no soportado para esta moneda",
      notSupportBlockchain:
        "Los tokens de diferentes blockchains no pueden ser retirados",
      exceedLimit: "La cantidad de retiro está por debajo o excede el límite",
      notSupportBlockchainWithdrawal:
        "Retiros no soportados en esta cadena de bloques",
      submitSuccess: "Retiro enviado exitosamente",
      orderNotFound: "Pedido de retiro no encontrado",
      withdrawCanceled: "Retiro cancelado",
      userNotFound: "Usuario no encontrado",
    },
  },

  // 前端语言

  menu: {
    home: "Inicio",
    login: "Iniciar sesión",
    risk: "Información de riesgo",
    customer: "Centro de atención al cliente",
    ucenter: "Centro de usuarios",
    swap: "Intercambio rápido",
    select: "Seleccionar moneda",
    borrow: "Préstamo",
    pool: "Minería de liquidez",
    level: "Nivel",
    account: "Detalles de la cuenta",
    withdraw: "Retirar",
    market_fund: "Fondo de mercado",
  },
  header: { subtitle: "Piscina de liquidez" },
  common: {
    cancel: "Cancelar",
    confirm: "Confirmar",
    cancelPay: "Pago cancelado por el usuario",
    enterPassword: "Ingrese la contraseña",
    inactivated: "Su cuenta no está activada",
    trxInsufficient: "Sus TRX no son suficientes para pagar la orden",
    copySuccess: "{msg} copiado exitosamente",
    copyFailed: "Error al copiar",
  },
  app: {
    lineWallet: "Conectando con la billetera...",
  },
  foot: {
    home: "Inicio",
    swap: "Intercambio",
    borrow: "Préstamo",
    pool: "Minería",
    ucenter: "Mi",
  },
  language: {
    title: "Seleccionar idioma",
  },
  extend: {
    snackbar: {
      close: "Cerrar",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "OK",
      success: "La transacción fue exitosa",
      fail: "Transacción fallida",
    },
  },

  home: {
    typewriter1: "Una revolución en las criptomonedas,",
    typewriter2: "está ocurriendo en silencio,",
    typewriter3: "¿Estás listo ¡Entonces únete a nosotros ahora",
    urlSubtitle: "¿Qué podemos hacer",
    blockSubtitle: "Criptomonedas admitidas",
    swapTitle: "Intercambio de criptomonedas",
    borrowTitle: "Préstamos con garantía",
    poolTitle: "Minería de liquidez",
    swapDesc:
      "Ofrecemos intercambio de criptomonedas entre diferentes cadenas para lograr la interoperabilidad.",
    borrowDesc:
      "Proporcionamos préstamos con garantía cripto para mejorar el valor de los activos.",
    poolDesc:
      "Gana rendimientos uniéndote a la minería de liquidez con tus criptomonedas.",
    swapBtn: "Intercambiar",
    borrowBtn: "Pedir préstamo",
    poolBtn: "Piscina de liquidez",
  },
  swap: {
    title: "Mercado de intercambio rápido",
    subtitle:
      "Admite el intercambio libre entre diversas criptomonedas, con mínimas comisiones de plataforma.",
    placeholder: "Ingresa la cantidad",
    toAddressPlaceholder: "Ingresa la dirección de {block_name}",
    toAddressLabel: "Dirección de recepción",
    currentRate: "Tasa de cambio actual",
    commission: "Comisión de intercambio",
    btn: "Intercambiar",
    records: "Historial de intercambios",
    goPay: "Ir a pagar",
    dialogTitle: "Aviso de intercambio",
    dialogMessage: "¿Deseas iniciar el intercambio",
    emptyAddress: "La dirección de recepción no puede estar vacía",
    createOrderFail: "Error al crear la orden de intercambio",
    orderTitle: "Orden de intercambio",
    orderSubtitle: "Verificar la información de la orden de intercambio",
    orderNumber: "Número de orden",
    status: {
      all: "Todos",
      wait: "Pendiente de pago",
      paySuccess: "Pago exitoso",
      success: "Éxito",
      fail: "Fallido",
    },
    tableHeaders: {
      swap_token: "Criptomoneda a intercambiar",
      from_num: "Cantidad pagada",
      transfer_num: "Cantidad recibida",
      transfer_commission: "Comisión de intercambio",
      rate: "Tasa de cambio",
      create_time: "Fecha del intercambio",
      status: "Estado",
    },
    orderItems: {
      swap_token: "Criptomoneda intercambiada",
      transfer_num: "Cantidad esperada para recibir",
      transfer_commission: "Comisión de intercambio",
      to_address: "Dirección de recepción",
      from_num: "Cantidad a pagar",
    },
  },

  borrow: {
    title: "Préstamo con garantía",
    subtitle:
      "Crea valor al usar una criptomoneda como garantía para obtener otra. Solo admite préstamos en la misma cadena.",
    fromPlaceholder: "Ingresa la cantidad a garantizar",
    fromLabel: "Cantidad a garantizar",
    toPlaceholder: "Ingresa la cantidad a solicitar en préstamo",
    toLabel: "Cantidad disponible para préstamo",
    toAddressPlaceholder: "Ingresa la dirección de {block_name}",
    toAddressLabel: "Dirección de recepción",
    currentRate: "Tasa de cambio actual",
    pledge: "Porcentaje de garantía",
    btn: "Solicitar préstamo",
    records: "Historial de transacciones",
    goPay: "Ir a pagar",
    addBorrow: "Añadir garantía",
    repayment: "Reembolso",
    none: "Ninguno",
    dialogTitle: "Aviso de préstamo",
    dialogSubtitle: "¿Deseas comenzar el préstamo con garantía",
    emptyAddress: "La dirección de recepción no puede estar vacía",
    notChain: "No se admiten préstamos entre cadenas",
    createOrderFail: "Error al crear la orden de préstamo",
    orderTitle: "Información del préstamo",
    orderSubtitle: "Verificar la información de la orden de préstamo",
    repaymentTitle: "Orden de reembolso",
    repaymentSubtitle: "Información de la orden de reembolso del préstamo",
    subOrderDialogTitle: "Cantidad de garantía",
    subOrderDialogPlaceholder: "Ingresa la cantidad a añadir como garantía",
    subOrderTitle: "Información de la garantía adicional",
    subOrderSubtitle: "Información de la orden de garantía adicional",
    orderNumber: "Número de orden",
    status: {
      all: "Todos",
      wait: "Pendiente de pago",
      borrowing: "En préstamo",
      over: "Finalizado",
      disbursement: "Desembolso en proceso",
      repaymentSuccess: "Reembolso exitoso",
      liquidated: "Liquidado",
    },
    tableHeaders: {
      borrow_token: "Criptomoneda a garantizar",
      from_num: "Cantidad garantizada",
      transfer_num: "Cantidad solicitada en préstamo",
      transfer_commission: "Comisión de transacción",
      pledge: "Porcentaje de garantía",
      interest: "Intereses",
      create_time: "Fecha de transacción",
      status: "Estado",
      operate: "Operación",
    },
    repaymenItems: {
      swap_token: "Criptomoneda a garantizar",
      transfer_num: "Capital prestado",
      interest: "Intereses",
      repayment: "Cantidad a devolver",
      from_num: "Garantía a devolver",
    },
    subOrderItems: {
      from_num: "Monto a pagar",
      token: "Criptomoneda garantizada",
    },
    orderItems: {
      token_symbol: "Criptomoneda a garantizar",
      transfer_num: "Cantidad esperada para recibir",
      transfer_commission: "Comisión de transacción",
      pledge: "Porcentaje de garantía",
      to_address: "Dirección de recepción",
      from_num: "Monto a pagar",
    },
  },

  pool: {
    header: "Minería de Liquidez",
    headerSubtitle:
      "Participa en la nueva minería de staking y obtén más ganancias de monedas duales",
    totalEarnings: "Ganancias Totales",
    todayEarnings: "Ganancias de Hoy",
    minePool: "Pool de Minería",
    mySupply: "Mis Ganancias",
    myTeam: "Equipo",
    poolTime: "Tiempo de Bloque",
    product: {
      lockBtn: "Beneficios de Staking",
      myLockPools: "Mi Staking",
      joined: "Unido",
      earned: "Ganado",
      profitTime: "Tiempo de Ganancias",
      currentChain: "Blockchain Actual",
      otherChains: "Otros Blockchains",
      supplyInformationUnavailable:
        "La información de suministro no está disponible actualmente para el blockchain actual",
      belongingChain: "Blockchain de Pertinencia",
      supplyToken: "Token de Suministro",
      marketPrice: "Precio de Mercado",
      settlementMethod: "Método de Liquidación",
      day: "Días",
      periodAPY: "APY",
      assets: "Tus Activos",
      dailyIncome: "Ganancias Diarias Estimadas",
      join: "Unirse",
      joined: "Unido",
      supply: "Suministro",
      orderNumber: "Número de Orden",
      supplyProduct: "Producto de Suministro",
      supplyCurrency: "Moneda de Suministro",
      supplyAmount: "Cantidad de Suministro",
      interestRate: "APY",
      estimatedEarnings: "Ganancias Estimadas",
      paymentAddress: "Dirección de Pago",
      supplyOrder: "Orden de Suministro",
      checkOrderInfo: "Verificar Información de la Orden de Suministro",
      pledged: {
        ChooseInfo: "Elegir Período de Bloqueo",
        lockupPeriod: "Período de Bloqueo",
        lockNum: "Cantidad de Bloqueo",
        lockTime: "Período de Bloqueo",
        lockLimit: "límite mínimo",
        originalDpr: "APY Original",
        lockDpr: "APY de Bloqueo",
        allDpr: "APY Total",
        lockCaption:
          "Durante el período de bloqueo, no puedes operar este activo, pero las ganancias aumentarán.",
        confirmInfo: "Confirmar Información",
        confirmMessage:
          "¿Estás seguro de que deseas bloquear este activo durante {day} días para aumentar las ganancias?",
        day: "días",
        ok: "Confirmar",
      },
    },
    record: {
      orderNumber: "Número de orden",
      supplyAssets: "Activos de suministro",
      supplyAmount: "Cantidad de suministro",
      supplyPeriod: "Período de suministro",
      remainingPeriod: "Período restante",
      interestRate: "DPR",
      transactionTime: "Tiempo de transacción",
      status: "Estado",
      all: "Todos",
      unpaid: "No pagado",
      income: "En curso",
      completed: "Completado",
      pendingPayment: "Pago pendiente",
      goToPay: "Pagar ahora",
      earning: "En curso",
      withdraw: "Retirar",
      successfulSubmission: "Envío exitoso, espera la aprobación...",
      withdrawalOrderApplicationFailed: "Solicitud de retiro fallida",
      day: "día",
      incomeAsset: "Activos de ganancias",
      incomeNum: "Cantidad de ganancias",
      uNum: "Ingreso real (U)",
      uRate: "Tipo de cambio (U)",
      incomeTime: "Tiempo de ganancias",
    },
    team: {
      inviteCode: "Código de invitación",
      teamMembers: "Miembros del equipo",
      inviteInfo: "Información de invitación",
      firstLevelInvite: "Primer",
      secondLevelInvite: "Segundo",
      thirdLevelInvite: "Tercer",
      copySuccess: "¡Copiado exitosamente",
      copyError:
        "Esta función de copiado automático no es compatible con este navegador",
    },
  },

  ucenter: {
    risk: {
      riskInfo: "Información de Riesgo de tu Cuenta",
      remove: "Eliminar",
      checkInfo:
        "El sistema ha detectado un riesgo anormal en tu cuenta de activos ({token}). Para salvaguardar tus fondos, toma medidas inmediatas.",
      removeRisk: "Eliminar Riesgo",
      riskInformation: "Información de Riesgo",
      removeSuccess: "Eliminación Exitosa",
    },
    withdraw: {
      withdraw: "Retirar",
      withdrawRecords: "Historial de Retiros",
      withdrawNum: "Cantidad a Retirar",
      withdrawCurrency: "Moneda a Retirar",
      currentRate: "Tasa de Cambio Actual",
      withdrawLimit: "Retirada Límite",
      fee: "Tarifa",
      estimatedArrival: "Llegada Estimada",
      cancel: "Cancelar",
      pendingReview: "Revisión Pendiente",
      withdrawalOrderInProgress: "Orden de Retiro en Progreso",
      withdrawalOrderSuccess: "Retiro Exitoso",
      success: "Éxito",
      fail: "Fallido",
      rejected: "Rechazado",
      withdrawalOrderEnded: "Orden de Retiro Finalizada",
      selectAll: "Seleccionar Todo",
      withdrawReminder: "Recordatorio de Retiro",
      withdrawConfirmation: "¿Deseas retirar {0} {1}",
      withdrawSuccessMessage: "Solicitud de Retiro Exitosa",
      withdrawCancelMessage: "Retiro Cancelado",
      userCenter: "Centro de Usuarios",
      accountWithdrawal: "Retiro de Cuenta",
      enterWithdrawalAmount: "Ingresa la cantidad a retirar",
      withdrawMin: "El monto mínimo de retiro por transacción es {0} USDT.",
      withdrawMax: "El monto máximo de retiro por transacción es {0} USDT.",
      withdrawButton: "Retirar",
      withdrawRecordsTitle: "Historial de Retiros",
      orderNumber: "Número de Orden",
      withdrawalAsset: "Retiro de activos",
      withdrawalAmountLabel: "Monto de retiro",
      withdrawalNumLabel: "Cantidad de retiro",
      withdrawalShowXuLabel: "Tarifa de transacción",
      applicationTime: "Fecha de Solicitud",
      status: "Estado",
      withdrawalOrderStatusPendingReview: "Pendiente de revisión",
      withdrawalOrderStatusProcessing: "Procesando",
      withdrawalOrderStatusRejected: "Rechazado",
      withdrawalOrderStatusInProgress: "En progreso",
      withdrawalOrderStatusSuccess: "Retiro exitoso",
      withdrawalOrderStatusFaild: "Retiro fallido",
      withdrawalOrderStatusCancel: "Cancelado",
      cancelWithdrawal: "Cancelar",
    },
    marketFund: {
      marketFund: "Fondo del Mercado",
      stableMarketFund:
        "Mercado de Fondos Estables, Mejorando la Liquidez de la Plataforma",
      yieldRate: "Tasa de Rendimiento",
      todayEarnings: "Ganancias de Hoy",
      yourLevel: "Tu Nivel",
      upgradeLevel: "Mejorar Nivel",
      totalEarnings: "Ganancias Totales del Fondo",
      accountBalance: "Saldo de Cuenta",
      ownedFund: "Fondos Propios",
      myFunds: "Mis Fondos",
      orderNumber: "Número de Orden",
      amount: "Monto",
      time: "Hora de Compra",
      status: "Estado",
      all: "Todos",
      transferIn: "Transferir a",
      earnings: "Ganancias",
      transferOut: "Transferir desde",
      transferInNum: "Monto a Transferir",
      transferOutNum: "Monto a Transferir",
      inputNum: "Ingresa la Cantidad",
      insufficientBalance: "Saldo Insuficiente",
      buySuccess: "Compra Exitosa",
      insufficientFundBalance: "Saldo de Fondos Insuficiente",
      transferOutSuccess: "Transferencia Exitosa",
      buyReminder: "Recordatorio de Compra",
      buyFundMessage: "¿Deseas comprar {num} USDT de Fondos",
      transferReminder: "Recordatorio de Transferencia",
      transferFundMessage:
        "¿Deseas transferir {num} USDT de Fondos Esto reducirá tus ganancias de Fondos.",
      fundTime: "Tiempo de financiación",
      earningsTime: "Tiempo de ganancias",
    },
    level: {
      levelExplanation: "Explicación de Niveles",
      marketFundLevelEquity: "Equidad del Nivel del Fondo del Mercado",
      contribution: "Contribución",
      contributionValue: "Tu Contribución",
      levelIncomeSystem: "Sistema de Ganancias por Niveles",
      level: "Nivel",
      contributionU: "Contribución (U)",
      incomeRate: "Tasa de Rendimiento",
      howToImproveLevel: "¿Cómo Mejorar tu Nivel?",
      liquidityPool: "Piscina de Liquidez",
      improveContribution:
        "Puedes mejorar tu Contribución al suministrar cualquier producto en la Piscina de Liquidez.",
      levelImpact:
        "Tu nivel afecta la tasa de rendimiento del Fondo del Mercado. Para mantener la equidad entre los clientes de la plataforma, las ganancias del Fondo del Mercado se basan en el valor en USDT del suministro del usuario a la plataforma.",
      yourContribution: "Tu Contribución",
      howToImproveLevelDesc:
        "Ingresa a la Piscina de Liquidez y suministra cualquier producto para mejorar tu Contribución y Nivel.",
    },
    customer: {
      userCenter: "Centro de Usuarios",
      customerCenter: "Centro de Atención al Cliente",
    },
    account: {
      orderNumber: "Número de Orden",
      transactionAmount: "Monto de Transacción",
      transactionTime: "Hora de Transacción",
      transactionType: "Tipo de Transacción",
      transactionRebate: "Reembolso de Transacción",
      userWithdrawal: "Retiro de Usuario",
      withdrawalOrderRejected: "Orden de Retiro Rechazada",
      supplyPrincipal: "Principal de Suministro",
      withdrawalOrderRefused: "Orden de Retiro Rechazada",
      userCenter: "Centro de Usuarios",
      accountDetails: "Detalles de Cuenta",
      filterSettings: "Configuración de Filtros",
      all: "Todos",
      income: "Ingresos",
      expenditure: "Gastos",
      poolIncome: "Ingresos de Piscina",
      lockIncome: "Ingresos de bloqueo",
      fundTransferOut: "Transferencia de Fondos Saliente",
      fundTransferIn: "Transferencia de Fondos Entrante",
      userCancel: "Cancelación de Usuario",
    },
    index: {
      userBalance: "Saldo del Usuario",
      marketFund: "Fondo",
      withdraw: "Retirar",
      pledgedAssets: "Bloqueo",
      onChainAssets: "Cadena",
      informationSettings: "Configuración de Información",
      myLedger: "Mi Libro Mayor",
      customerCenter: "Centro de Atención al Cliente",
      passwordSettings: "Configuración de Contraseña",
      languageSettings: "Configuración de Idioma",
    },
    userLogin: {
      inputPassword: "Ingresa tu Contraseña de Seguridad",
      setPassword: "Establece tu Contraseña de Seguridad",
      forgetPassword: "¿reiniciar",
      loginSuccess: "Inicio de Sesión Exitoso",
      setPassSuccess: "Contraseña de Seguridad Establecida Exitosamente",
      resetSuccessful: "Restablecimiento de contraseña exitoso",
      signFailed: "Error de firma",
      resetReminder: "Recordatorio de reinicio",
      resetInstructions:
        "Tu contraseña de seguridad se reiniciará a: 123456. Una vez completado el reinicio, por favor ve al centro de miembros para cambiar la contraseña. ¿Estás seguro/a de que deseas continuar?",
    },
  },
};
