<template>
  <v-snackbar v-model="show" :timeout="timeout" top :class="color">
    <span>{{ message }} </span>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false" class="close">
        {{ $t("extend.snackbar.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "info",
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss">
.close {
  font-size: 12px;
}
.info {
  .v-snack__wrapper.theme--dark {
    background-color: #01579b;
  }
  .close {
    color: #0078d3;
  }
}

.success {
  .v-snack__wrapper.theme--dark {
    background-color: #009688;
  }
  .close {
    color: #00d3be;
  }
}

.warning {
  .v-snack__wrapper.theme--dark {
    background-color: #889600;
  }
  .close {
    color: #bfd400;
  }
}

.error {
  .v-snack__wrapper.theme--dark {
    background-color: #880096;
  }
  .close {
    color: #bb00cf;
  }
}
</style>
