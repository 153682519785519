export default {
  // 服务语言
  serve: {
    common: {
      verifyFailed: "验证失败",
      login: "请先进行登录",
    },
    approval: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
      lockFailed: "锁仓失败",
      lockSuccess: "锁仓成功",
      notAddress: "系统未配置地址",
      assetsLimit: "您的资产未达到锁仓最低限制",
    },
    approvalGain: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    auth: {
      querySuccess: "查询成功",
      notSupportedChainType: "不支持的公链类型",
      alreadyRegistered: "已注册",
      loginFailedUnsupportedType: "不支持的类型",
      accountNotFound: "账户不存在",
      incorrectPassword: "密码错误",
    },
    base: {
      notDomin: "未配置域名",
    },
    block: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    borrow: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    borrowOrder: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
      notSupportedBorrow: "当前代币不支持借入",
      notSupportedCrossChain: "不支持跨链",
      orderAmountTooSmall: "订单金额太小",
      orderAmountTooLarge: "订单金额太大",
      addSuccess: "新增成功",
      addFailed: "新增失败",
      orderNotFound: "订单不存在",
    },
    exchange: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    exchangeOrder: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
      notSupportedExchange: "当前代币不支持兑换",
      exchangeAmountTooSmall: "兑换金额太小",
      exchangeAmountTooLarge: "兑换金额太大",
      addSuccess: "新增成功",
      addFailed: "新增失败",
    },
    lixibao: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
      userInfoNotFound: "用户信息不存在",
      insufficientBalance: "余额不足",
      addSuccess: "新增成功",
    },
    pool: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    poolGoods: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    poolOrder: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
      productNotFound: "当前产品不存在",
      productOutOfStock: "当前产品已下架",
      orderNotFound: "订单不存在",
      orderNotInProfit: "当前订单不在收益中",
      userInfoNotFound: "用户信息不存在",
      addSuccess: "新增成功",
      addFailed: "新增失败",
      applyWithdrawSuccess: "申请提现成功",
      applyWithdrawFailed: "申请提现失败",
    },
    token: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    tokenPrice: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    user: {
      accountNotFound: "账户不存在",
      passwordError: "密码错误",
      resetPwdSuccess: "密码重置成功",
      resetPwdFailed: "密码重置失败",
      setNotifySuccess: "设置成功",
      setNotifyFailed: "设置失败",
      loginSuccess: "登录成功",
      loginFailed: "登录失败",
      emptyPwd: "未重置密码",
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    vip: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    withdraw: {
      querySuccess: "查询成功",
      queryFailed: "查询失败",
    },
    withdrawOrder: {
      querySuccess: "查询成功",
      insufficientBalance: "余额不足",
      notSupportWithdrawal: "当前代币不支持提现",
      notSupportBlockchain: "不同区块链的代币不能提现",
      notSupportBlockchainWithdrawal: "当前区块链不支持提现",
      exceedLimit: "提现数量低于或超过限制",
      submitSuccess: "提现提交成功",
      orderNotFound: "提现订单不存在",
      withdrawCanceled: "提现已取消",
      userNotFound: "用户不存在",
    },
  },
  // 前端语言
  menu: {
    home: "首页",
    login: "用户登录",
    risk: "风险信息",
    customer: "客服中心",
    ucenter: "用户中心",
    swap: "闪兑",
    select: "币种",
    borrow: "借贷",
    pool: "流动性挖矿",
    level: "等级",
    account: "账户明细",
    withdraw: "提现",
    market_fund: "市场基金",
  },
  header: { subtitle: "流动性矿池" },
  common: {
    cancel: "取消",
    confirm: "确认",
    cancelPay: "用户取消支付",
    enterPassword: "请输入密码",
    inactivated: "您的账号未激活",
    trxInsufficient: "您的TRX不足支付订单",
    copySuccess: "复制{msg}成功",
    copyFailed: "复制失败",
  },
  app: {
    lineWallet: "连接钱包中...",
  },
  foot: {
    home: "首页",
    swap: "闪兑",
    borrow: "借贷",
    pool: "矿池",
    ucenter: "我的",
  },
  language: {
    title: "选择语言",
  },
  extend: {
    snackbar: {
      close: "关闭",
    },
  },
  payOrder: {
    payOrder: {
      confirm: "确定",
      success: "交易成功",
      fail: "交易失败",
    },
  },
  home: {
    typewriter1: "一场数字货币的变革,",
    typewriter2: "正在悄然的发生,",
    typewriter3: "而你准备好了吗？那么，现在请加入我们！",
    urlSubtitle: "我们能做什么？",
    blockSubtitle: "支持币种",
    swapTitle: "数字货币兑换",
    borrowTitle: "抵押借贷",
    poolTitle: "流动性挖矿",
    swapDesc: "提供跨链币种交换，实现多链互通愿景",
    borrowDesc: "提供抵押借贷币业务，增强区块价值属性",
    poolDesc: "通过您手上的闲置币加入到平台赚取收益",
    swapBtn: "兑换",
    borrowBtn: "借入",
    poolBtn: "矿池",
  },
  swap: {
    title: "闪兑市场",
    subtitle: "支持多种币种自由兑换,平台只收取少量手续费",
    placeholder: "输入数量",
    toAddressPlaceholder: "请输入{block_name}地址",
    toAddressLabel: "接收地址",
    currentRate: "当前汇率",
    commission: "兑换佣金",
    btn: "兑换",
    records: "兑换记录",
    goPay: "去支付",
    dialogTitle: "兑换提醒",
    dialogMessage: "是否开始兑换？",
    emptyAddress: "收款地址不能为空",
    createOrderFail: "创建订单失败",
    orderTitle: "兑换订单",
    orderSubtitle: "核对兑换订单信息",
    orderNumber: "订单号",
    status: {
      all: "全部",
      wait: "待支付",
      paySuccess: "支付成功",
      success: "成功",
      fail: "失败",
    },
    tableHeaders: {
      swap_token: "兑换币种",
      from_num: "支付数量",
      transfer_num: "接收数量",
      transfer_commission: "交易佣金",
      rate: "汇率",
      create_time: "交易时间",
      status: "状态",
    },
    orderItems: {
      swap_token: "交换币种",
      transfer_num: "预计接收",
      transfer_commission: "交易佣金",
      to_address: "接收地址",
      from_num: "应付金额",
    },
  },
  borrow: {
    title: "抵押借币",
    subtitle: "以币抵押其他币种,创造升值。只支持同链借贷",
    fromPlaceholder: "请输入抵押数量",
    fromLabel: "抵押数量",
    toPlaceholder: "请输入借入数量",
    toLabel: "可借入",
    toAddressPlaceholder: "请输入{block_name}地址",
    toAddressLabel: "接收地址",
    currentRate: "当前汇率",
    pledge: "抵押率",
    btn: "借入",
    records: "交易记录",
    goPay: "去支付",
    addBorrow: "增加抵押",
    repayment: "还款",
    none: "无",
    dialogTitle: "借入提醒",
    dialogSubtitle: "是否开始抵押借入？",
    emptyAddress: "收款地址不能为空",
    notChain: "不支持跨链",
    createOrderFail: "创建订单失败",
    orderTitle: "借贷信息",
    orderSubtitle: "核对借贷订单信息",
    repaymentTitle: "还款订单",
    repaymentSubtitle: "借贷还款订单信息",
    subOrderDialogTitle: "抵押数量",
    subOrderDialogPlaceholder: "请输入增加抵押数量",
    subOrderTitle: "增加抵押信息",
    subOrderSubtitle: "增加抵押订单信息",
    orderNumber: "订单号",
    status: {
      all: "全部",
      wait: "待支付",
      borrowing: "借贷中",
      over: "已结束",
      disbursement: "放款中",
      repaymentSuccess: "还款成功",
      liquidated: "已清算",
    },
    tableHeaders: {
      borrow_token: "交换币种",
      from_num: "抵押数量",
      transfer_num: "借入数量",
      transfer_commission: "交易佣金",
      pledge: "抵押率",
      interest: "利息",
      create_time: "交易时间",
      status: "状态",
      operate: "操作",
    },
    repaymenItems: {
      swap_token: "交换币种",
      transfer_num: "借入本金",
      interest: "利息",
      repayment: "应还数量",
      from_num: "返还抵押",
    },
    subOrderItems: {
      from_num: "应付金额",
      token: "抵押币种",
    },
    orderItems: {
      token_symbol: "交换币种",
      transfer_num: "预计接收",
      transfer_commission: "交易佣金",
      pledge: "抵押率",
      to_address: "接收地址",
      from_num: "应付金额",
    },
  },
  pool: {
    header: "流动性挖矿",
    headerSubtitle: "参与全新锁仓挖矿 获取更多双币收益",
    totalEarnings: "总收益",
    todayEarnings: "今日收益",
    minePool: "矿池",
    mySupply: "我的收益",
    myTeam: "团队",
    poolTime: "区块时间",
    product: {
      lockBtn: "锁仓增益",
      myLockPools: "我的锁仓",
      joined: "已加入",
      earned: "已收益",
      profitTime: "收益时间",
      currentChain: "当前公链",
      otherChains: "其他公链",
      supplyInformationUnavailable: "当前公链暂无供应信息",
      belongingChain: "所属公链",
      supplyToken: "供应代币",
      marketPrice: "市场价格",
      settlementMethod: "结算方式",
      day: "天",
      periodAPY: "DPR",
      assets: "您的资产",
      dailyIncome: "预计收益/日",
      join: "加入",
      joined: "已加入",
      supply: "供应",
      orderNumber: "订单号",
      supplyProduct: "供应产品",
      supplyCurrency: "供应币种",
      supplyAmount: "供应数量",
      interestRate: "DPR",
      estimatedEarnings: "预计收益",
      paymentAddress: "收款地址",
      supplyOrder: "供应订单",
      checkOrderInfo: "核对供应订单信息",
      pledged: {
        ChooseInfo: "选择锁仓周期",
        lockupPeriod: "锁仓周期",
        lockNum: "锁仓数量",
        lockTime: "锁仓时间",
        lockLimit: "最低限制",
        originalDpr: "DPR(原始)",
        lockDpr: "DPR(锁仓)",
        allDpr: "DPR(总)",
        lockCaption: "锁仓期间您不能操作该资产，但收益增加。",
        confirmInfo: "确认信息",
        confirmMessage: "是否确认对该资产锁仓({day})天，增加收益？",
        day: "天",
        ok: "确定",
      },
    },
    record: {
      orderNumber: "订单号",
      supplyAssets: "供应资产",
      supplyAmount: "供应数量",
      interestRate: "DPR",
      transactionTime: "交易时间",
      status: "状态",
      all: "全部",
      unpaid: "未支付",
      income: "收益中",
      completed: "已结束",
      pendingPayment: "待支付",
      goToPay: "去支付",
      earning: "收益中",
      withdraw: "提现",
      successfulSubmission: "提交成功，请等待审核...",
      withdrawalOrderApplicationFailed: "申请提现失败",
      day: "天",
      incomeAsset: "收益资产",
      incomeNum: "收益数量",
      uNum: "实际收益(U)",
      uRate: "汇率(U)",
      incomeTime: "收益时间",
      transactionType: "交易类型",
      transactionRebate: "交易返佣",
    },
    team: {
      inviteCode: "邀请码",
      teamMembers: "团队人数",
      inviteInfo: "邀请信息",
      firstLevelInvite: "一级邀请",
      secondLevelInvite: "二级邀请",
      thirdLevelInvite: "三级邀请",
      copySuccess: "复制成功",
      copyError: "该浏览器不支持自动复制",
    },
  },
  ucenter: {
    risk: {
      riskInfo: "您的账户风险信息",
      remove: "解除",
      checkInfo:
        "系统检测到您的({token})资产账户有异常风险，为保障资金安全，请立即处理。",
      removeRisk: "解除风险",
      riskInformation: "风险信息",
      removeSuccess: "解除成功",
    },
    withdraw: {
      withdraw: "提现",
      withdrawRecords: "提现记录",
      withdrawNum: "提现数量",
      withdrawLimit: "提现限制",
      withdrawCurrency: "提现币种",
      currentRate: "当前汇率",
      fee: "手续费",
      estimatedArrival: "预计到账",
      cancel: "取消",
      pendingReview: "审核中",
      withdrawalOrderInProgress: "出款中",
      withdrawalOrderSuccess: "提现成功",
      success: "成功",
      fail: "失败",
      rejected: "驳回",
      withdrawalOrderEnded: "已结束",
      selectAll: "全部",
      withdrawReminder: "提现提醒",
      withdrawConfirmation: "是否提现{0}{1}？",
      withdrawSuccessMessage: "申请提现成功",
      withdrawCancelMessage: "取消成功",
      userCenter: "用户中心",
      accountWithdrawal: "账户提现",
      enterWithdrawalAmount: "请输入提现数量",
      withdrawMin: "单次最小提现数量为:{0} USDT",
      withdrawMax: "单次最大提现数量为:{0} USDT",
      withdrawButton: "提现",
      withdrawRecordsTitle: "提现记录",
      orderNumber: "订单号",
      withdrawalAsset: "提现资产",
      withdrawalAmountLabel: "提现金额",
      withdrawalNumLabel: "提现数量",
      withdrawalShowXuLabel: "手续费",
      applicationTime: "申请时间",
      status: "状态",
      withdrawalOrderStatusPendingReview: "审核中",
      withdrawalOrderStatusProcessing: "处理中",
      withdrawalOrderStatusRejected: "驳回",
      withdrawalOrderStatusInProgress: "出款中",
      withdrawalOrderStatusSuccess: "提现成功",
      withdrawalOrderStatusFaild: "提现失败",
      withdrawalOrderStatusCancel: "已取消",
      cancelWithdrawal: "取消",
    },
    marketFund: {
      marketFund: "市场基金",
      stableMarketFund: "稳定基金市场，提升平台流动资金",
      yieldRate: "收益率",
      todayEarnings: "今日收益",
      yourLevel: "您的等级",
      upgradeLevel: "提升等级",
      totalEarnings: "基金总收益",
      accountBalance: "账户余额",
      ownedFund: "拥有基金",
      myFunds: "我的基金",
      orderNumber: "订单号",
      amount: "金额",
      time: "买入时间",
      status: "状态",
      all: "全部",
      transferIn: "转入",
      earnings: "收益",
      transferOut: "转出",
      transferInNum: "买入数量",
      transferOutNum: "转出数量",
      inputNum: "请输入数量",
      insufficientBalance: "账户余额不足",
      buySuccess: "买入成功",
      insufficientFundBalance: "基金余额不足",
      transferOutSuccess: "转出成功",
      buyReminder: "买入提醒",
      buyFundMessage: "是否开始买入基金数量: {num} USDT？",
      transferReminder: "转出提醒",
      transferFundMessage:
        "是否转出基金数量: {num} USDT，转出后基金收益将减少？",
      fundTime: "基金时间",
      earningsTime: "收益时间",
    },
    level: {
      levelExplanation: "等级说明",
      marketFundLevelEquity: "市场基金等级权益",
      contribution: "贡献度",
      contributionValue: "您的贡献度",
      levelIncomeSystem: "等级收益制度",
      level: "等级",
      contributionU: "贡献度(U)",
      incomeRate: "收益率",
      howToImproveLevel: "如何提升等级？",
      liquidityPool: "流动性矿池",
      improveContribution: "供应任何产品都均可提升您的贡献度来提升等级。",
      levelImpact:
        "等级影响您在市场基金的收益率，为了维护平台客户的公平权益，市场基金的收益按照用户对平台的供应数量转为USDT价值为准。",
      yourContribution: "您的贡献度",
      howToImproveLevelDesc:
        "进入流动性矿池，供应任何产品都均可提升您的贡献度来提升等级。",
    },
    customer: {
      userCenter: "用户中心",
      customerCenter: "客服中心",
    },
    account: {
      orderNumber: "订单号",
      transactionAmount: "交易金额",
      transactionTime: "交易时间",
      transactionType: "交易类型",
      transactionRebate: "交易返佣",
      userWithdrawal: "用户提现",
      withdrawalOrderRejected: "提现驳回",
      supplyPrincipal: "供应本金",
      withdrawalOrderRefused: "提现拒绝",
      userCenter: "用户中心",
      accountDetails: "账户明细",
      filterSettings: "筛选设置",
      all: "全部",
      income: "收入",
      expenditure: "支出",
      poolIncome: "矿池收益",
      lockIncome: "锁仓收益",
      fundTransferOut: "基金转出",
      fundTransferIn: "基金转入",
      userCancel: "用户取消",
    },
    index: {
      userBalance: "用户余额",
      marketFund: "市场基金",
      withdraw: "提现",
      pledgedAssets: "锁仓资产",
      onChainAssets: "链上资产",
      informationSettings: "信息/设置",
      myLedger: "我的账本",
      customerCenter: "客服中心",
      passwordSettings: "密码设置",
      languageSettings: "语言设置",
    },
    userLogin: {
      inputPassword: "请输入安全密码",
      setPassword: "请设置您的安全密码",
      forgetPassword: "重置？",
      loginSuccess: "登录成功",
      setPassSuccess: "设置安全密码成功",
      resetSuccessful: "重置密码成功",
      signFailed: "签名错误",
      resetReminder: "重置提醒",
      resetInstructions:
        "即将把安全密码重置为:123456, 重置完成请到会员中心修改新密码, 请确定继续操作？",
    },
  },
};
