<template>
  <v-app-bar app flat color="transparent" height="60" collapse-on-scroll>
    <v-img
      max-height="32"
      max-width="32"
      :src="require('@/assets/logo.png')"
    ></v-img>
    <v-toolbar-title class="text-h6 ml-2 light-blue--text"
      >AbcPool<small class="text-caption">{{
        $t("header.subtitle")
      }}</small></v-toolbar-title
    >
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon>
      <v-btn @click="languageVisible = true" icon
        ><v-icon color="#777">mdi-web</v-icon></v-btn
      >
    </v-app-bar-nav-icon>
    <Language
      :languageVisible="languageVisible"
      @close="languageVisible = false"
    />
  </v-app-bar>
</template>
<script>
import Language from "@/components/Language.vue";
export default {
  name: "Header",
  components: { Language },
  data: () => ({ languageVisible: false }),
};
</script>
