import { apiBlockRetrieve } from "@/api/api";
export const block = {
  namespaced: true,
  state: {
    blockMap: null,
    blocks: null,
  },
  mutations: {
    setBlockMap(state, blockMap) {
      state.blockMap = blockMap;
    },
    setBlocks(state, blocks) {
      state.blocks = blocks;
    },
  },

  actions: {
    // 从后台获取动态路由
    async getBlock({ commit, state }) {
      if (state.blockMap) {
        return state.blockMap;
      } else {
        const res = await apiBlockRetrieve();
        const blockMap = {};
        res &&
          res.map((item) => {
            blockMap[item.id] = item;
          });

        commit("setBlocks", res);
        commit("setBlockMap", blockMap);
        return state.blockMap;
      }
    },
  },

  getters: {
    getBlockMap(state) {
      return state.blockMap;
    },
    getBlocks(state) {
      return state.blocks;
    },
  },
};
