import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import echarts from "echarts";
import SnackbarPlugin from "@/plugins/SnackbarPlugin";
import DialogPlugin from "./plugins/DialogPlugin";
import payInputPlugin from "./plugins/payInputPlugin";
import PayOrderPlugin from "./plugins/PayOrderPlugin";

import i18n from "./locale";

Vue.use(SnackbarPlugin);
Vue.use(DialogPlugin);
Vue.use(payInputPlugin);
Vue.use(PayOrderPlugin);


Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
