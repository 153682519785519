const ethers = require('ethers');
// ABI规则转换
const AbiCoder = ethers.utils.AbiCoder;
// const ADDRESS_PREFIX_REGEX = /^(41)/;
const ADDRESS_PREFIX = '41';

// ABI规则转换

function decodeParams(types, output, ignoreMethodHash) {
  //解码

  if (!output || typeof output === 'boolean') {
    ignoreMethodHash = output;
    output = types;
  }

  if (ignoreMethodHash && output.replace(/^0x/, '').length % 64 === 8)
    output = '0x' + output.replace(/^0x/, '').substring(8);

  const abiCoder = new AbiCoder();

  if (output.replace(/^0x/, '').length % 64)
    throw new Error(
      'The encoded string is not valid. Its length must be a multiple of 64.'
    );
  return abiCoder.decode(types, output).reduce((obj, arg, index) => {
    if (types[index] == 'address')
      arg = ADDRESS_PREFIX + arg.substr(2).toLowerCase();
    obj.push(arg);
    return obj;
  }, []);
}

function decode_balance(data) {
  // 金额解码
  //必须是0x开头
  let outputs = '0x' + data;
  //['uint256']是返回值类型的列表，如果有多个返回值，在按照顺序填写类型
  const res = decodeParams(['uint256'], outputs, false);
  return res.toString();
}

module.exports = { decode_balance };
