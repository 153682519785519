<template>
  <v-dialog v-model="dialogVisible">
    <v-card>
      <v-card-title class="text-subtitle-1">
        {{ options.title }}
      </v-card-title>
      <v-card-text v-html="options.message" class="text-left"> </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="resolveDialog(false)"
          v-if="options.showCancelButton"
          color="grey"
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn text @click="resolveDialog(true)">{{
          $t("common.confirm")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
    reject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
    };
  },
  methods: {
    resolveDialog(value) {
      this.dialogVisible = false;
      this.resolve(value);
    },
  },
};
</script>
<style scoped>
.theme--dark.v-card {
  background-color: #01579b !important;
}
</style>
