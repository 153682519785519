import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";
import en from "vuetify/lib/locale/en";
import es from "vuetify/lib/locale/es";
import ja from "vuetify/lib/locale/ja";
import ko from "vuetify/lib/locale/ko";
import zhHans from "vuetify/lib/locale/zh-Hans";
import zhHant from "vuetify/lib/locale/zh-Hant";
import "@mdi/font/css/materialdesignicons.css";
import { getLocalStorage } from "@/utils/local-storage";
Vue.use(Vuetify);

const { language } = getLocalStorage("language");
const vuetify = new Vuetify({
  lang: {
    locales: { en, es, ja, ko, zhHans, zhHant },
    current: language || "en",
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.lightBlue.darken4,
      },
    },
  },
});

export default vuetify;
