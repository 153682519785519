<template>
  <v-dialog v-model="dialogVisible">
    <v-card class="text-left pt-7" flat>
      <v-card-subtitle>{{ options.subtitle }}</v-card-subtitle>
      <v-card-text class="text-left">
        <v-list-item
          v-for="item of options.items"
          :key="item.title"
          v-if="options.items"
        >
          <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
          <v-list-item-subtitle class="grey--text">{{
            item.value
          }}</v-list-item-subtitle>
        </v-list-item>
        <v-text-field
          class="mt-2"
          :placeholder="options.placeholder"
          dense
          dark
          :label="options.label"
          color="blue"
          v-model="num"
          clearable
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="resolveDialog(false)"
          v-if="options.showCancelButton"
          color="grey"
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn depressed color="primary" small @click="resolveDialog(num)">{{
          $t("common.confirm")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
    reject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
      num: 1,
    };
  },
  created() {
    this.num = this.options.value || 1;
  },
  methods: {
    resolveDialog(value) {
      this.dialogVisible = false;
      this.resolve(value);
    },
  },
};
</script>
<style scoped>
.theme--dark.v-card {
  background-color: #01579b !important;
}
</style>
