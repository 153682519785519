import Vue from "vue";
import Snackbar from "@/components/extend/snackbar.vue"; // Snackbar组件的路径
import vuetify from "./vuetify";
import i18n from "@/locale";

const SnackbarPlugin = {
  install(Vue) {
    Vue.prototype.$snackbar = {
      showSnackbar(message, color = "success", timeout = 3000) {
        const SnackbarComponent = Vue.extend(Snackbar);
        const snackbar = new SnackbarComponent({
          i18n,
          vuetify,
          propsData: {
            message,
            color,
            timeout,
          },
        });
        snackbar.$mount();
        document.body.appendChild(snackbar.$el);
      },
    };
  },
};

Vue.use(SnackbarPlugin);

export default SnackbarPlugin;
