<template>
  <div>
    <v-dialog v-model="languageVisible" @click:outside="$emit('close')">
      <v-card class="text-left pb-6">
        <v-subheader>{{ $t("language.title") }}</v-subheader>
        <v-virtual-scroll :items="languages" :item-height="50" height="310">
          <template v-slot:default="{ item }">
            <v-list-item
              :disabled="item.select"
              @click="selectLanguage(item.locale)"
            >
              <v-list-item-avatar>
                <v-avatar size="24" class="white--text">
                  <v-img :src="item.img"></v-img>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle class="text-subtitle-2">{{
                  item.lang
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="item.select">
                <v-icon size="16">mdi-check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiLangRetrieve } from "@/api/api";
import { setLocalStorage } from "@/utils/local-storage";
export default {
  name: "Language",
  props: ["languageVisible"],
  data: () => ({ languages: [] }),
  async mounted() {
    await this.getLangRetrieve();
  },
  methods: {
    async getLangRetrieve() {
      let res = await apiLangRetrieve();
      this.languages = res.langs;
    },
    selectLanguage(locale) {
      this.$vuetify.lang.current = locale;
      this.$i18n.locale = locale;
      setLocalStorage({ language: locale });
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.theme--dark.v-card {
  background-color: #01579b !important;
}
</style>
