import axios from "axios";
import i18n from "@/locale/index"; //
import store from "@/store";
// import Mock from "mockjs";
import Vue from "vue";

// function stringFormat(formatted, args) {
//   for (let i = 0; i < args.length; i++) {
//     let regexp = new RegExp("\\{" + i + "\\}", "gi");
//     formatted = formatted.replace(regexp, args[i]);
//   }
//   return formatted;
// }

// create an axios instance
const service = axios.create({
  withCredentials: true,
  baseURL: "/api", // api 的 base_url
  timeout: 30000, // request timeout
});

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZGRyZXNzIjoiMHhlMGUzOWJlNzZkZTQ2ZjQ1YzFiOThiZjU0YTY5NTI5ODk5NjY4N2UxIiwibWVtYmVySWQiOjUyMiwiYmlkIjoxLCJpYXQiOjE2ODY0ODE3NzIsImV4cCI6MTk4NjQ4MTc3Mn0.5ze7yjI5cAp9dG7OOZNBwtzrvIuDLQA5RTtkNK0Yqoc
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.headers["X-Token"]) {
      //config.headers['X-Token'] = `${
      //  window.localStorage.getItem('Authorization') || ''
      //}`;
      //const token = store.state.auth.token;
      config.headers["X-Token"] = `${store.state.globals.token || ""}`;
    }
    if (!config.headers["x-Address"]) {
      // config.headers['x-Address'] = `${
      //   window.localStorage.getItem('address') || ''
      // }`;
      config.headers["x-Address"] = `${store.state.globals.address || ""}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 没有状态码就默认成功，成功状态码为0
    const code = res.errno || 0;
    // 错误信息
    const errmsg = res.errmsg || "Network Error";
    if (code === 501) {
      setTimeout(() => {
        window.location = "/";
      }, 1500);
      Vue.prototype.$snackbar.showSnackbar(i18n.t(res.errmsg), "error", 3000);
      return Promise.reject(errmsg);
    } else if (code === 502) {
      Vue.prototype.$snackbar.showSnackbar(i18n.t(errmsg), "error", 3000);
      return Promise.reject(res);
    } else if (code === 401) {
      Vue.prototype.$snackbar.showSnackbar(i18n.t(errmsg), "error", 3000);
      store.commit("globals/setLogin", false);
      store.commit("globals/clearInfo", {});
      window.location = "/";
      return Promise.reject(res);
    } else if (code === 402) {
      Vue.prototype.$snackbar.showSnackbar(i18n.t(errmsg), "error", 3000);
      store.commit("globals/setLogin", false);
      store.commit("globals/clearInfo", {});
      return Promise.reject(res);
    } else if (code !== 0) {
      // 非5xx的错误属于业务错误，留给具体页面处理
      if (res.errmsg != null) {
        Vue.prototype.$snackbar.showSnackbar(i18n.t(res.errmsg), "error");
      } else {
        Vue.prototype.$snackbar.showSnackbar("Network Error", "error");
      }
      return Promise.reject(res);
    } else {
      if (res.data != null) {
        return res.data;
      } else {
        return res;
      }
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Vue.prototype.$snackbar.showSnackbar("Network Error", "error", 3000);
    return Promise.reject("err" + error);
  }
);

export default service;
