import store from '@/store';

export const getBlock = async type => {
  await store.dispatch('block/getBlock');
  return store.getters['block/getBlocks'][type];
};

export const getBlocks = async () => {
  await store.dispatch('block/getBlock');
  return store.getters['block/getBlocks'];
};

export const getBlockMap = async () => {
  await store.dispatch('block/getBlock');
  return store.getters['block/getBlockMap'];
};

export const getToken = async type => {
  await store.dispatch('token/getToken');
  return store.getters['token/getTokenMap'][type];
};

export const getTokens = async () => {
  await store.dispatch('token/getToken');
  return store.getters['token/getTokens'];
};

export const getTokenMap = async () => {
  await store.dispatch('token/getToken');
  return store.getters['token/getTokenMap'];
};
