export const getRandomAlphaNum = (len) => {
  var rdmString = "";
  for (
    ;
    rdmString.length < len;
    rdmString += Math.random().toString(36).substr(2)
  );
  return rdmString.substr(0, len).toUpperCase();
};

export const GetRandomNum = (Min, Max) => {
  var Range = Max - Min;
  var Rand = Math.random();
  return Min + Rand * Range;
};
// 想要保留多少位，直接给对应的变量的赋值即可
// 此处是保留前三位和后四位
export const hiddenCardId = (str, frontLen = 3, endLen = 3, len = 3) => {
  //str:要进行隐藏的变量  frontLen: 前面需要保留几位    endLen: 后面需要保留几位
  // var len = str.length - frontLen - endLen;
  var start = "";
  for (var i = 0; i < len; i++) {
    start += "*";
  }
  // 最后的返回值由三部分组成
  return (
    str.substring(0, frontLen) + start + str.substring(str.length - endLen)
  );
};

export const doCopy = (val, msg = "") => {
  try {
    const _val = val.toString();
    var save = function (e) {
      e.clipboardData.setData("text/plain", _val);
      e.preventDefault();
    };
    document.addEventListener("copy", save);
    document.execCommand("copy");
    document.removeEventListener("copy", save);
    Vue.prototype.$snackbar.showSnackbar(
      this.$t("common.copySuccess", msg),
      "success"
    );
  } catch (error) {
    Vue.prototype.$snackbar.showSnackbar(this.$t("common.copyFailed"), "error");
  }
};
