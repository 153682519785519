<template>
  <div>
    <!-- transparent -->

    <v-carousel
      :show-arrows="false"
      continuous
      :interval="3000"
      cycle
      hide-delimiters
      :height="96"
      class="d-flex justify-center mt-9"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
        style="width: 96px"
      ></v-carousel-item>
    </v-carousel>

    <typewriter class="text-subtitle-1" ref="typewriter" :interval="50">
      <v-sheet class="transparent py-6 text-subtitle-2">
        <!-- <v-icon size="98" color="light-blue" class="mb-2">mdi-ethereum</v-icon> -->
        <div class="grey--text">
          {{ $t("home.typewriter1") }}
        </div>
        <div class="grey--text">
          {{ $t("home.typewriter2") }}
        </div>
        <div class="grey--text">
          {{ $t("home.typewriter3") }}
        </div>
      </v-sheet>
    </typewriter>

    <!-- banner -->
    <v-subheader class="text-subtitle-1 light-blue--text my-3"
      >{{ $t("home.urlSubtitle", { name: "xxxx" }) }}
    </v-subheader>

    <v-card
      class="mx-auto mt-3 py-3"
      color="rgb(0,0,0,12%)"
      flat
      v-for="url in urls"
      :key="url.title"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-subtitle-2">{{ url.title }}</div>
          <v-list-item-subtitle class="grey--text">{{
            url.desc
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar size="48">
          <img :src="url.icon" width="48" height="48" />
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions class="px-3">
        <v-btn depressed block color="primary" :to="url.url">
          {{ url.btn_text }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Blocks -->
    <v-subheader class="light-blue--text mt-3 text-subtitle-1">{{
      $t("home.blockSubtitle")
    }}</v-subheader>

    <v-list class="transparent" v-for="item in blocks" :key="item.id">
      <v-subheader class="light-blue--text"
        >{{ item.short_name }}<v-divider inset></v-divider
      ></v-subheader>
      <v-list-item v-for="token in groups[item.id]" :key="token.id">
        <v-list-item-avatar size="24">
          <img :src="token.pic" />
        </v-list-item-avatar>
        <v-list-item-content class="ml-3">
          <v-list-item-title class="text-left text-subtitle-2">{{
            token.token_symbol
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-left text-body-2 grey--text">{{
            token.contract_address
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="light-blue--text text-subtitle-2">
          <v-list-item-action-text
            ><v-icon size="12" class="mr-1">$</v-icon
            >{{ token.price_in_usd }}</v-list-item-action-text
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card class="mx-auto my-3 py-6" color="rgb(0,0,0,12%)" flat>
      <v-row>
        <v-col cols="3" v-for="partner of partners" :key="partner">
          <v-btn icon>
            <v-img
              :src="require(`@/assets/icon/${partner}.png`)"
              contain
              width="48"
            ></v-img>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
const _ = require("lodash");
import typewriter from "vue-type-writer";
import { getBlocks, getTokens } from "@/utils/dictionary";
import { hiddenCardId } from "@/utils/uitl";
export default {
  name: "Home",
  components: { typewriter },
  data() {
    return {
      tokens: [],
      blocks: [],
      groups: {},
      partners: ["grey-bitcoin", "grey-ethereum", "grey-tron", "grey-binance"],
      items: [
        {
          src: require("@/assets/icon/btc.png"),
        },
        {
          src: require("@/assets/icon/eth.png"),
        },
        {
          src: require("@/assets/icon/bnb.png"),
        },
        {
          src: require("@/assets/icon/tron.png"),
        },
      ],
    };
  },
  computed: {
    urls() {
      return [
        {
          title: this.$t("home.swapTitle"),
          desc: this.$t("home.swapDesc"),
          icon: require("@/assets/icon/swap.png"),
          btn_text: this.$t("home.swapBtn"),
          url: "/swap",
        },
        {
          title: this.$t("home.borrowTitle"),
          desc: this.$t("home.borrowDesc"),
          icon: require("@/assets/icon/loan.png"),
          btn_text: this.$t("home.borrowBtn"),
          url: "/borrow",
        },
        {
          title: this.$t("home.poolTitle"),
          desc: this.$t("home.poolDesc"),
          icon: require("@/assets/icon/mining.png"),
          btn_text: this.$t("home.poolBtn"),
          url: "/pool",
        },
      ];
    },
  },
  async mounted() {
    // this.$refs.typewriter.$emit("typewrite");
    this.blocks = await getBlocks();
    this.tokens = await getTokens();
    this.groups = _.groupBy(this.tokens, "bid");
  },
  methods: {
    hiddenCardId(contract_address) {
      if (contract_address == null) {
        return "";
      }
      if (contract_address === "") {
        return "";
      }
      return hiddenCardId(contract_address, 8, 8);
    },
  },
};
</script>
<style scoped lang="scss"></style>
