import OrderInfo from "@/components/pay_order/PayOrder";
import vuetify from "./vuetify";
import i18n from "@/locale";

const OrderInfoPlugin = {
  install(Vue) {
    Vue.prototype.$payOrderDialog = {
      show(options) {
        if (!options.hasOwnProperty("showCancelButton"))
          options.showCancelButton = true;
        return new Promise((resolve, reject) => {
          const orderInfoComponent = new Vue({
            i18n,
            vuetify,
            render: (h) =>
              h(OrderInfo, {
                props: {
                  options,
                  resolve,
                  reject,
                },
              }),
          }).$mount();

          const orderInfoContainer = document.createElement("div");
          document.body.appendChild(orderInfoContainer);
          orderInfoContainer.appendChild(orderInfoComponent.$el);
        });
      },
    };
  },
};

export default OrderInfoPlugin;
