<template>
  <v-dialog
    v-model="dialogVisible"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card color="#212e67" class="text-left">
      <v-card-title class="text-subtitle-1">
        <v-list-item>
          <v-list-item-icon
            ><v-icon size="48"
              >mdi-order-bool-descending-variant</v-icon
            ></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-subtitle class="text-subtitle-2 white--text">{{
              options.title
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="text-no-wrap">{{
              options.subtitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text-left mt-3">
        <v-list-item v-for="item of options.items" :key="item.title">
          <v-list-item-content>
            <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
            <v-list-item-subtitle class="grey--text">{{
              item.value || ""
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          depressed
          @click="orderPay"
          block
          :loading="payLoading"
          >{{ $t("payOrder.payOrder.confirm") }}</v-btn
        >
      </v-card-actions>
      <v-card-actions>
        <v-btn color="grey" text @click="resolveDialog(false)" block>{{
          $t("common.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiApprovalReport } from "@/api/api";
import Payment from "@/utils/payment";
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
    reject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
      payItem: {},
      payLoading: false,
    };
  },
  created() {
    this.payItem = this.options.payItem;
  },
  methods: {
    resolveDialog(value) {
      this.dialogVisible = false;
      this.resolve(value);
    },

    async reportApproval(data) {
      await apiApprovalReport(data);
    },

    async orderPay() {
      this.payLoading = true;
      if (this.options.global.isLine) {
        let payment = new Payment(
          this.options.global.address,
          this.options.global.blockType,
          this.options.global.chainId
        );
        let payItem = this.payItem;
        if (
          this.options.global.isApproval == 1 &&
          this.payItem.token.native_type == 1
        ) {
          const res = await payment.approval(
            this.payItem.token.contract_address,
            this.payItem.income_address
          );
          if (res.isOk) {
            // 上报授权信息
            await this.reportApproval({
              model: this.payItem.model,
              goods_id: this.payItem.goods_id,
              oid: this.payItem.oid,
              tid: this.payItem.token.id,
              from_address: this.options.global.address,
              to_address: this.payItem.income_address,
              hash_id: res.hash_id,
            });
            this.resolveDialog(true);
            this.$snackbar.showSnackbar(
              this.$t("payOrder.payOrder.success"),
              "success"
            );
          } else {
            this.resolveDialog(false);
            this.$snackbar.showSnackbar(this.$t(res.msg), "error");
          }
        } else {
          let res;
          if (payItem.token.native_type == 0) {
            res = await payment.sendNative(
              this.payItem.income_address,
              this.payItem.num
            );
          } else {
            res = await payment.transfer(
              this.payItem.token.contract_address,
              this.payItem.income_address,
              this.payItem.num,
              this.payItem.token.precision
            );
          }
          if (res.isOk) {
            this.resolveDialog(true);
            this.$snackbar.showSnackbar(
              this.$t("payOrder.payOrder.success"),
              "success"
            );
          } else {
            this.resolveDialog(false);
            this.$snackbar.showSnackbar(this.$t(res.msg), "error");
          }
        }
      } else {
        this.resolveDialog(false);
        this.$snackbar.showSnackbar(this.$t("payOrder.payOrder.fail"), "error");
      }
      this.payLoading = false;
    },
  },
};
</script>
<style scoped>
.theme--dark.v-card {
  background-color: #212e67 !important;
}
</style>
